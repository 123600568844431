import { makeStyles } from "@material-ui/core/styles";
import bigMain from "../../../assets/images/img/bigimage.svg";

export const useStyles = makeStyles((theme) => ({
  root: {
    // height: '100vh',
  },
  image: {
    display: 'flex',
    height: 500,
    backgroundImage: `${bigMain} !important`
    // backgroundColor:
    //   theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],

  },

  paper: {
    // margin: theme.spacing(2, 8),
    margin: '16px auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  formLogin: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  submit: {
    margin: theme.spacing(3, 8, 2),
  },
  
}));
