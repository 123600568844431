import React from 'react';
import Typography from "@material-ui/core/Typography";
import SearchBar from "../../search";
import { NewDownloadAddButton } from '../../index'
import { Add } from '@material-ui/icons';

function Index(props) {
  const {
    tableLabel,searchValue, setSearchValue, showSearch = true, openModal,
    exportData, searchPlaceholder = 'search...', downloadHandler,
    className, styles, loading, fileName
  } = props;
  return (
    <div className={` table-title-container ${className}`} style={styles}>
      <Typography variant='h4' className='table-title-graph'>
        {tableLabel}
      </Typography>
      <div>
        {(showSearch && searchValue != null) && <SearchBar value={searchValue} onChange={setSearchValue} placeholder={searchPlaceholder} />}
        <NewDownloadAddButton openModal={openModal} downloadHandler={downloadHandler} data={exportData} loading={loading} fileName={fileName} />
      </div>
    </div>
  );
}

export default Index;
