// Users Action Types
export const GET_USERS_LIST_START = "GET_USERS_LIST_START";
export const GET_USERS_LIST_SUCCESS = "GET_USERS_LIST_SUCCESS";
export const GET_USERS_LIST_FAILURE = "GET_USERS_LIST_FAILURE";

export const GET_SERVICES_LIST_START = "GET_SERVICES_LIST_START";
export const GET_SERVICES_LIST_SUCCESS = "GET_SERVICES_LIST_SUCCESS";
export const GET_SERVICES_LIST_FAILURE = "GET_SERVICES_LIST_FAILURE";
export const GET_SERVICES_DATA_START = "GET_SERVICES_DATA_START";
export const GET_SERVICES_DATA_SUCCESS = "GET_SERVICES_DATA_SUCCESS";
export const GET_SERVICES_DATA_FAILURE = "GET_SERVICES_DATA_FAILURE";
export const SERVICE_MESSAGE_HANDLER = "SERVICE_MESSAGE_HANDLER";

export const SET_USER_SERVICES_LIST = "USER_SERVICES_LIST";
export const SET_CATEGORY_LIST = "SET_CATEGORY_LIST";

export const GET_USER_DATA_START = "GET_USER_DATA_START";
export const GET_USER_DATA_SUCCESS = "GET_USER_DATA_SUCCESS";
export const GET_USER_DATA_FAILURE = "GET_USER_DATA_ERROR";


export const GET_MANIFEST_DATA_START = "GET_MANIFEST_DATA_START";
export const GET_MANIFEST_DATA_SUCCESS = "GET_MANIFEST_DATA_SUCCESS";
export const GET_MANIFEST_DATA_FAILURE = "GET_MANIFEST_DATA_ERROR";


export const MESSAGE_HANDLER = "MESSAGE_HANDLER";

export const ADD_NEW_USER_START = "ADD_NEW_USER_START";
export const ADD_NEW_USER_SUCCESS = "ADD_NEW_USER_SUCCESS";
export const ADD_NEW_USER_FAILURE = "ADD_NEW_USER_FAILURE";

export const UPDATE_USER_START = "UPDATE_USER_START";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

export const DELETE_USER_START = "DELETE_USER_START";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

export const SIGNUP_START = "SIGNUP_START";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";

export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const LOGIN_ERROR_HANDLER_SUCCESS = "LOGIN_ERROR_HANDLER_SUCCESS";
export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";


export const DASHBOARD_START = "DASHBOARD_START";
export const DASHBOARD_SUCCESS = "DASHBOARD_SUCCESS";
export const DASHBOARD_FAILURE = "DASHBOARD_FAILURE";
export const DASHBOARD_ERROR_HANDLER_SUCCESS = "DASHBOARD_ERROR_HANDLER_SUCCESS";

export const CONFIRMATION_MODAL = "CONFIRMATION_MODAL";
export const HELP_HANDLER = "HELP_HANDLER";

export const CHANGE_PASSWORD_START = "CHANGE_PASSWORD_START";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

export const CHANGE_PASS_START = "CHANGE_PASS_START";
export const CHANGE_PASS_SUCCESS = "CHANGE_PASS_SUCCESS";
export const CHANGE_PASS_FAILURE = "CHANGE_PASS_FAILURE";

export const FORGOT_PASSWORD_START = "FORGOT_PASSWORD_START";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const VERIFY_PASSWORD_START = "VERIFY_PASSWORD_START";
export const VERIFY_PASSWORD_SUCCESS = "VERIFY_PASSWORD_SUCCESS";
export const VERIFY_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const UPLOAD_MANIFEST_START = "UPLOAD_MANIFEST_START";
export const UPLOAD_MANIFEST_SUCCESS = "UPLOAD_MANIFEST_SUCCESS";
export const UPLOAD_MANIFEST_FAILURE = "UPLOAD_MANIFEST_FAILURE";
export const MANAGE_RECORD_START = "MANAGE_RECORD_START";
export const MANAGE_RECORD_SUCCESS = "MANAGE_RECORD_SUCCESS";
export const MANAGE_RECORD_FAILURE = "MANAGE_RECORD_FAILURE";
export const GET_MANIFEST_LIST_START = "GET_MANIFEST_LIST_START";
export const GET_MANIFEST_LIST_SUCCESS = "GET_MANIFEST_LIST_SUCCESS";
export const GET_MANIFEST_LIST_FAILURE = "GET_MANIFEST_LIST_FAILURE";
export const GET_RECORDS_LIST_START = "GET_RECORDS_LIST_START";
export const GET_RECORDS_LIST_SUCCESS = "GET_RECORDS_LIST_SUCCESS";
export const GET_RECORDS_LIST_FAILURE = "GET_RECORDS_LIST_FAILURE";
export const SCAN_DATA_START = "SCAN_DATA_START";
export const SCAN_DATA_SUCCESS = "SCAN_DATA_SUCCESS";
export const SCAN_DATA_FAILURE = "SCAN_DATA_FAILURE";
export const GET_SCAN_LIST_START = "GET_SCAN_LIST_START";
export const GET_SCAN_LIST_SUCCESS = "GET_SCAN_LIST_SUCCESS";
export const GET_SCAN_LIST_FAILURE = "GET_SCAN_LIST_FAILURE";
export const GET_SCAN_GRAPH_DATA_START = "GET_SCAN_GRAPH_DATA_START";
export const GET_SCAN_GRAPH_DATA_SUCCESS = "GET_SCAN_GRAPH_DATA_SUCCESS";
export const GET_SCAN_GRAPH_DATA_FAILURE = "GET_SCAN_GRAPH_DATA_FAILURE";
export const GET_SCAN_PIE_GRAPH_START = "GET_SCAN_PIE_GRAPH_START";
export const GET_SCAN_PIE_GRAPH_SUCCESS = "GET_SCAN_PIE_GRAPH_SUCCESS";
export const GET_SCAN_PIE_GRAPH_FAILURE = "GET_SCAN_PIE_GRAPH_FAILURE";
export const UPLOAD_IMAGES_START = "UPLOAD_IMAGES_START";
export const UPLOAD_IMAGES_SUCCESS = "UPLOAD_IMAGES_SUCCESS";
export const UPLOAD_IMAGES_FAILURE = "UPLOAD_IMAGES_FAILURE";
export const UPLOAD_RECORD_IMAGE_START = "UPLOAD_RECORD_IMAGE_START";
export const UPLOAD_RECORD_IMAGE_SUCCESS = "UPLOAD_RECORD_IMAGE_SUCCESS";
export const UPLOAD_RECORD_IMAGE_FAILURE = "UPLOAD_RECORD_IMAGE_FAILURE";
export const DOWNLOAD_FILE_START = "DOWNLOAD_FILE_START";
export const DOWNLOAD_FILE_SUCCESS = "DOWNLOAD_FILE_SUCCESS";
export const DOWNLOAD_FILE_FAILURE = "DOWNLOAD_FILE_FAILURE";
export const RECORD_MESSAGE_HANDLER = "RECORD_MESSAGE_HANDLER";