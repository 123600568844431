import * as actionTypes from "../actionTypes";

export const getServicesList = (data) => {
    return {
        type: actionTypes.GET_SERVICES_LIST_START,
        payload: data,
    };
};
export const getServicesListSuccess = (data) => {
    return {
        type: actionTypes.GET_SERVICES_LIST_SUCCESS,
        payload: data,
    };
};
export const getServicesListFailure = (data) => {
    return {
        type: actionTypes.GET_SERVICES_LIST_FAILURE,
        payload: data,
    };
};
export const getServicesData = (data) => {
    return {
        type: actionTypes.GET_SERVICES_DATA_START,
        payload: data,
    };
};
export const getServicesDataSuccess = (data) => {
    return {
        type: actionTypes.GET_SERVICES_DATA_SUCCESS,
        payload: data,
    };
};
export const getServicesDataFailure = (data) => {
    return {
        type: actionTypes.GET_SERVICES_DATA_FAILURE,
        payload: data,
    };
};


export const  serviceMessageHandler = () => {
    return {
        type: actionTypes.SERVICE_MESSAGE_HANDLER,
        payload: null,
    };
};
