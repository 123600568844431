import React, { useEffect, useState } from "react";
import { AppBar, Drawer, List, Toolbar } from "@material-ui/core";
import clsx from "clsx";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { withRouter } from "react-router-dom";
import { useStyles } from "./styles";
import { getUserDataFromLocalStorage } from "../../services/utils";
import { downArrow, menuIcon, userProfile } from '../../assets/images/img/'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { PrimaryButton } from '../../components'
import HttpServices from '../../services/http.service'
import { helpHandler } from "../../store/actions";
import { useDispatch } from "react-redux";
import { rockLogo } from "../../assets/images/img";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import SettingsIcon from '@material-ui/icons/Settings';
import LockIcon from '@material-ui/icons/Lock';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import UpdatePasswordModal from '../../components/modals/updatePassModal';
// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';

const MySidebar = (props) => {
  const classes = useStyles();
  const { sidebar } = props;
  const [open, setOpen] = useState(true);
  const [userData, setUserData] = useState({});
  const [activeTab, setActiveTab] = useState("");
  const [profileOption, setProfileOption] = useState(false);
  const [openModel, setOpenModel] = React.useState(true);
  const [openUpdatePassModal, setOpenUpdatePassModal] = useState(false);
  const [headerTitle, setHeaderTitle] = useState("");

  useEffect(() => {
    let pathname = props.history.location.pathname.split("/")[1];
    if (pathname === 'dashboard') {
      setHeaderTitle("Dashboard");
    } else if (pathname === 'manifest') {
      setHeaderTitle("Manifest Management");
    } else if (pathname === 'qr-scan') {
      setHeaderTitle("QR Scans History");
    } else {
      setHeaderTitle("ScanPass Portal");
    }
    
  }, [props.history.location.pathname]);

  const _useEffect = () => {
    if (Boolean(window.navigator.vibrate)) {
      // It Supports

    }
    let userData = getUserDataFromLocalStorage();
    if (userData) {
      //  console.log('user data',userData)
      let username = userData?.personal_detail?.full_name
      // username = username.replace(/\b\w/g, l => l.toUpperCase())
      // document.title = username
      // document.title =`<button onClick={()=>alert('hi ')}>username</button>`
      setUserData(userData);
      highlightActiveTab();
    }
  };
  let user = getUserDataFromLocalStorage()
  const role = user && user.role

  const highlightActiveTab = () => {
    let pathname = props.history.location.pathname.split("/");
    let fullPath = props.history.location.pathname;
    let activeTab = pathname[pathname.length - 1];
    // console.log('this is active ', activeTab)
    if (activeTab) {
      // setActiveTab(activeTab);
      if (fullPath.search('cp') >= 0) {
        // activeTab=
        // props.history.push(`/cp/${activeTab}`);
      }
      // else if (activeTab !== 'dashboard')
      // console.log({activeTab})
      // props.history.push(`/${activeTab}`);
      // }
      // else {
      //     props.history.push('/dashboard')
    }
  };
  useEffect(_useEffect, []);

  const _useEffectActiveTab = () => {
    let pathname = props.history.location.pathname.split("/");
    let fullPath = props.history.location.pathname;
    let activeTab = pathname[1];
    // if (fullPath.search('cp') >= 0) {
    //   activeTab = 'cps'
    //   // activeTab = '/cp/' + pathname[pathname.length - 1]
    //   // props.history.push(`/cp/${activeTab}`);
    // }
    setActiveTab(activeTab);
  };
  useEffect(_useEffectActiveTab, [activeTab, props.history.location.pathname]);

  const onLogout = () => {
    localStorage.clear();
    // localStorage.removeItem('token')
    // localStorage.removeItem('user')
    HttpServices.removeUser()
    props.history && props.history.push("/login");
    // window.location.reload(false);
  };
  const onTabHandler = (e, tab) => {
    e.preventDefault();
    let tabKey = tab.key.toLowerCase();

    // console.log({tabKey})
    // if (tabKey !== activeTab) {
    // if(tabKey==='cp'){
    //   tabKey='cp/cp-list'
    // }
    // if (tabKey === "login") {
    //   props.history.push({pathname: `/login`});
    // }
    // if (tabKey === "dashboard") {
    //   props.history.replace('/dashboard');
    // } else
    //   {
    props.history.push({ pathname: `/${tabKey}` });
    // }
    setActiveTab(tabKey);
    // }
  };

  const handleDrawer = () => {
    window.navigator.vibrate(11500);
    // window.navigator.vibrate([500, 200, 800]);
    setOpen(!open);
  };

  const changePassword = () => {
    setOpenUpdatePassModal(true);
    // props.history.push({ pathname: `/changePassword`, state: { currentUser: userData } });
  }

  const dispatch = useDispatch()
  const helpClose = () => {
    dispatch(helpHandler({ open: false, data: null }))
  }
  useEffect(() => {
    setProfileOption(false)//close profile option i.e logout
    helpClose() // close help section
  }, [props])
  const handleClick = () => {
    setOpenModel(!openModel);
  };
  return (
    <div>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
          [classes.appBarShift1]: !open,
        })}
      >
        {/*<Toolbar style={{background: 'white', color: 'black', justifyContent: 'space-between'}}>*/}
        <Toolbar style={{ color: 'black', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ backgroundColor: 'transparent' }}
              className={'display-flex ' + clsx(classes.logoTextContainer, {
                [classes.logoTextContainerOpen]: open,
                [classes.logoTextContainerClose]: !open,
              })}
              classes={{
                paper: clsx({
                  [classes.logoTextContainer]: open,
                  [classes.logoTextContainerClose]: !open,
                }),
              }}
            >

              {/*<h3 className='app-logo-text' style={{display: open ? 'none' : 'inline'}}>cp-rrp</h3>*/}
              <span className='m-0 app-logo-text'> <img src={rockLogo} /></span>

            </div>
            <ListItemIcon onClick={handleDrawer} className='pl-small cursor-pointer'>
              {/* <img src={menuIcon} width="42px" /> */}
              {/*<menuIcon/>*/}
              {/*<MenuIcon style={{color: 'white'}}/>*/}
            </ListItemIcon>
            <div className="header-title-mn">
              <span>{headerTitle}</span>
              <span></span>
            </div>
          </div>
          <div className='logout-container cursor-pointer' onClick={() => setProfileOption(!profileOption)}>
            <img src={userProfile} alt={'profile'} title='Profile' className=' user-profile-icon' />
            <span className='text-capitalize font-14 text-bold'>{userData?.name} </span>
            <KeyboardArrowDownIcon color="disabled" className='ml-10' />
            {/* <img src={downArrow} alt={'options'} title='Profile' className='ml-10' style={{ width: 15 }} */}
            {/* /> */}
            {profileOption && <div style={{ overflowWrap: 'anywhere' }} className='logout-option-container'>
              <div className="center-pp-drop">
                <img src={userProfile} alt={'profile'} title='Profile' className=' pp-profile-img' />
                <span className="profile-container">{userData?.name} </span>
                <span className="profile-container">{userData?.email} </span>
              </div>
              <hr className="hr-drp-log" />
              {role !== '1' &&
                <>
                  <ListItem button onClick={changePassword} primary="Change Password">
                    <ListItemIcon>
                      <LockIcon style={{ color: '#fff' }} />
                    </ListItemIcon>
                    <ListItemText primary="Change Password" />
                  </ListItem>
                </>
              }
              <ListItem button onClick={onLogout} value='↶ Logout' primary="Change Password">
                <ListItemIcon>
                  <ExitToAppIcon style={{ color: '#fff' }} />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </ListItem>

              {/*<button className='btn custom-btn btn-3 btn-4'><span>logout</span></button>*/}
            </div>}
          </div>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        {/*<React.Fragment style={{backgroundColor:'red', marginTop:60}}>*/}
        {/*  /!*<ListItem style={{marginTop: -10, paddingBottom: 15}}>*!/*/}
        {/*  <ListItem style={{marginTop: -10, paddingBottom: 15}}>*/}
        {/*    <ListItemIcon onClick={handleDrawer} className='cursor-pointer'>*/}
        {/*      <MenuIcon/>*/}
        {/*    </ListItemIcon>*/}
        {/*    <span className="text-capitalize app-logo-text ">cp-rrp</span>*/}
        {/*  </ListItem>*/}
        {/*  <Divider/>*/}
        {/*</React.Fragment>*/}
        <div className="logo-scan">
            <img src={rockLogo} alt={""} />
          </div>
        <List style={{ paddingRight: 5, backgroundColor: '#fff', height: '100vh', overflow: 'auto' }}>
          <ListItem className={'sidebar-tab-text pb-0 text-black-normal m-0'}>
            <ListItemText
              primary={''}
              className={`text-capitalize ${open ? 'display-flex' : 'display-none'}`}
            />
          </ListItem>
          {(sidebar || []).map((obj, index) => {
            let key = obj.key.toLowerCase();
            let [textClass, iconColor] = activeTab == key ? ["active-tab", "white_icon"] : ["sidebar-tab-text", "black_icon"];

            let IconT = obj.icon;
            return (
              <React.Fragment key={`tabList ${index}`}>
                <ListItem
                  button
                  onClick={(e) => onTabHandler(e, obj)}
                  className={textClass}
                >
                  <ListItemIcon className='sidebar-tab-icon'>
                    {/*<IconT className={iconColor}/>*/}
                    <span className="colorGreen">
                      {/*<img src={obj.icon} className={`sidebar-tab-icon ${iconColor}`}*/}
                      <img src={activeTab==key ? obj.activeIcon:obj.icon} className={`sidebar-tab-icon `}
                        alt={""} />
                    </span>
                    {/*<img src={dashboardIcon} className={iconColor} alt={""}/>*/}
                  </ListItemIcon>
                  <ListItemText
                    primary={obj.text}
                    className={`text-capitalize ${open ? 'display-flex' : 'display-none'}`}
                  />
                </ListItem>
                {/*<Divider/>*/}
              </React.Fragment>
            );
          })}
        </List>
      </Drawer>
      <UpdatePasswordModal open={openUpdatePassModal} setOpen={setOpenUpdatePassModal} />
    </div>
  );
};
export default withRouter(MySidebar);
