import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { APP_DOMAIN_PREFIX } from './environment';
// import Maintenence from './Maintenence';

// if (APP_DOMAIN_PREFIX.split(':')[0] === 'https') {
//   console.log('%cStop!', 'color: red; font-size: 40px; font-weight: bold;');
//   console.log('%cThis is a browser feature intended for developers. If someone told you to copy-paste something here to enable a some feature or “hack” someone’s account, it is a scam and will give them access to your account.', 'color: blue; font-size: 18px; font-weight: bold;');
//   console.log('%cPlease close this window now.', 'color: blue; font-size: 18px; font-weight: bold;');

//   console.log = () => {}
// }

ReactDOM.render(
  <React.StrictMode>
    <App />
    {/* <Maintenence /> */}
  </React.StrictMode>,
  document.getElementById('root')
);
