import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Container, Fade, Grid, Modal} from '@material-ui/core';
import './styles.scss'
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHeading from "../dataTable/tableHeading";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import {getFormattedDate} from '../../environment'
import CustomTableCell from "../dataTable/tableCell";
import CancelIcon from '@material-ui/icons/Cancel';
import {PrimaryButton} from "../index";

const useStyles = makeStyles((theme) => ({
  modal: {
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
    border: 'none',
    // backgroundColor: 'red',
    marginTop: 20,
    // width: 500,

  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    // boxShadow: theme.shadows[5],
    // padding: theme.spacing(1, 2, 2, 3),
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    marginTop: 20,
    paddingRight: 10,

  },
}));

export default function LabelsListingModal(props) {
  const classes1 = useStyles();
  // const classes = tableStyles();
  const classes = {};
  let {
    open, setOpen, data, rejected = false, rejectedHandler, startDate, endDate
  } = props;

  const handleClose = () => {
    setOpen(false);
  };

  const reject = () => {
    handleClose();
    rejectedHandler()
  }

  startDate = startDate && getFormattedDate(startDate)
  endDate = endDate && getFormattedDate(endDate)

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes1.modal}

      open={open}
      // onClose={handleClose}
      // closeAfterTransition
      // BackdropComponent={Backdrop}
      // BackdropProps={{timeout: 500,}}
      elevation={9}
    >
      <Fade in={open}   style={{maxHeight:'95%'}}  className={'border-none overflow-auto mt-20'}>
        <Container component="main" maxWidth="md" style={{padding: 20, backgroundColor: 'white', borderRadius: 20, height:'600px', overflow:'auto'}}>
          <div className='display-flex align-items-center justify-content-between'>
            <h3 onClick={handleClose}
                className='m-0 text-capitalize '>{rejected ? ` invoice duplication ` : 'invoice contain following labels'} </h3>
            <CancelIcon className='cursor-pointer' onClick={handleClose} style={{color: 'red'}}/>
          </div>
          {rejected &&
          <p>Duplicate invoices corresponding to following labels between date <b>{startDate}</b> To<b> {endDate}</b>
          </p>}
          {
            (data || []).map((row, index) => {
              console.log(data)
              let keys = Object.keys(row);

              let rowItem = ''

              if (keys.length === 1) {
                rowItem = row[keys[0]]
                startDate = ''
                endDate = ''
              } else {
                rowItem = row['labels']
                startDate = row?.startDate
                endDate = row?.endDate
              }

              return (
                <div className={classes1.paper}>
                  <Grid container alignItems='center' justify={'space-between'}>
                    <Grid item><h3>{rowItem[0]?.parent_label_name}</h3></Grid>
                    {startDate && <Grid item>
                      <p> {`${startDate} To ${endDate}`}  </p>
                    </Grid>
                    }
                  </Grid>
                  {rowItem.map((item, index) => {
                    let grossRevenue = item?.grossRevenue;
                    grossRevenue = (grossRevenue && parseFloat(grossRevenue.toFixed(2))) || 0
                    let labelRevenue = item?.labelRevenue;
                    labelRevenue = (labelRevenue && parseFloat(labelRevenue.toFixed(2))) || 0
                    return (
                      <TableContainer>
                        <Table className={[classes.table, ' datatable-body']}>
                          <TableHeading
                            classes={classes}
                            // numSelected={selected.length}
                            // order={order}
                            // orderBy={orderBy}
                            // onRequestSort={handleRequestSort}
                            // rowCount={rows?.length || 0}
                            columns={'labelsListingHeadCells'}
                            // rows={rowItem}
                          />
                          <TableBody>
                            <TableRow hover key={row + index} className=''>
                              <CustomTableCell value={''}/>
                              <CustomTableCell value={item?.label_id}/>
                              <CustomTableCell value={item?.label_name}/>
                              <CustomTableCell value={parseInt((item?.label_share || 0) * 100)}/>
                              <CustomTableCell value={'PKR ' + grossRevenue}/>
                              <CustomTableCell value={'PKR ' + labelRevenue}/>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )
                  })}
                </div>
              );
            })
          }
          <div className={'display-flex justify-content-end'}>
            {rejected && <PrimaryButton value={`Don't show again`} onClick={reject}/>}
          </div>

        </Container>
      </Fade>
    </Modal>
  );
}

// todo
// add file download option in invoices report
