import { C_OTC_STORAGE, getFormattedDate, mobileNumberRegex, PASSWORD } from "../environment";
import XLSX from "xlsx";
import cpLogo from '../assets/images/emailImages/Logo.svg'
// src\assets\images\emailImages\Logo.svg


export const getUserDataFromLocalStorage = () => {
  const userObj =
    (localStorage.getItem(C_OTC_STORAGE) &&
      JSON.parse(localStorage.getItem(C_OTC_STORAGE))) ||
    null;
  return userObj;
};
export const setUserDataInLocalStorage = (user) => {
  localStorage.setItem(C_OTC_STORAGE, JSON.stringify(user));
}

export const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
window.valid = validateEmail

export const validateMobile = (value) => { // check minimum length 7 max length is out bound
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return (value.match('mobileNumberRegex'));
}
window.checkMobile = validateMobile;
export const validatePassword = (password) => {
  if (
    (password !== undefined) && (password.trim() === "" || password.length < PASSWORD.passwordLength)
    // || !values.password.match('[a-z]')
    // || !values.password.match('[A-Z]')
    // || !values.password.match('[0-9]')
    // || !values.password.match(/[\*\.\!\@\#\$\%\^\&\(\)\{\}\[\]\:\;\<\>\,\.\?\/\~\_\+\-\=\|\\]/)
  ) {
    return (PASSWORD.passwordLengthError)
  }
  return null;
}
export const onChangeInput = (value, key, prevState, setState) => {
  setState((prevState) => ({
    ...prevState,
    values: {
      ...prevState.values,
      [key]: value,
    },
    errors: {
      ...prevState.errors,
      [key]: "",
    }
  }));
};

export const validateConfirmPassword = (password, confirmPassword) => {
  let errors = {};
 
  if(password==='' && confirmPassword===''){
    console.log("both empty")
    return true
  }

  else if (password !== confirmPassword) {
      errors={
          ...errors,
          confirm_password: "Passwords do not match"
      };
      return false;
  } 
  else {
      errors={
          ...errors,
          confirm_password: ""
      };
      return true;
  }
};

export const validateInputs = (values) => {
  let keys = Object.keys(values);
  const len = keys.length;
  let recordedErrors = {};
  let value = ''
  for (let i = 0; i < len; i++) {
    value = values[keys[i]];
    if (!(value == '0') && (!value || value === undefined || value.toString().trim().length <= 0)) {
      recordedErrors = { ...recordedErrors, [keys[i]]: `${keys[i]} is required` }
    }
  }
  return (recordedErrors);
}


// export const getDateFromDateTime = (date) => new Date(date).toLocaleString().split(',')[0]
export const getDateFromDateTime = (date) => new Date(date).toLocaleDateString()
window.getDate = getDateFromDateTime

export const dateDisplayFormat = (date) => (new Date(date).toDateString()).substring(3);
window.dateDisplayFormat = dateDisplayFormat

export function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

window.addDays = addDays
window.subDays = subDays

export function subDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() - days);
  return result;
}

export function commaSeparateValue(value) {
  return value.toLocaleString();
}

export function generateRandomValue() {
  return (new Date().getTime()).toString(18)
}

export const readFile = (excelFile, uploadHandler, sheetName, service_id) => {
  let f = excelFile;
  const reader = new FileReader();
  reader.onload = (evt) => {
    /* Parse data */
    const bstr = evt.target.result;
    const wb = XLSX.read(bstr, { type: "binary" });
    /* Get first worksheet */
    const sheetName1 = wb.SheetNames[0];
    const ws = wb.Sheets[sheetName || sheetName1];
    // const ws = wb.Sheets[0];
    // console.log(sheetName1,sheetName)
    // console.log(ws)
    const data = XLSX.utils.sheet_to_json(ws, { raw: true })
    // data.length=10
    let fullDataLength = data.length;
    // let fullDataLength = 55;
    // 500000
    let newDataArray = []
    console.log(service_id)
    let dataLength = 0;
    let chunk = 10000;

    let i = 1
    if (fullDataLength > 30000) {
      // make api cal in chunks of 20000 record

      while (dataLength <= fullDataLength) {

        (function a(dataLength) {
          setTimeout(() => {

            if (dataLength > fullDataLength) {
              newDataArray = data.slice(dataLength - chunk, fullDataLength)
            } else {
              newDataArray = data.slice(dataLength - chunk, dataLength)
            }
            // console.log( new Date().getMinutes(),new Date().getSeconds())
            uploadHandler({ data: newDataArray, service_id: service_id })
          }, 20000 * i++)
        }(dataLength += chunk));
      }
    } else {
      uploadHandler({ data: data, service_id: service_id })
    }

  };
  reader.readAsBinaryString(f);
}


export const helpClick = (data = [], handler) => {
  handler({ open: true, data })
}

const getEmailReceiver = (status, cpName) => {
  let receiver = (status == 1 || status == 5) ? 'Finance Team' :
    (status == 2 || status == 3 || status == 8 || status == 9) ? "Business Team" :
      (status == 4 || status == 6 || status == 11) ? cpName :
        (status == 7 || status == 10) ? `Business Team/${cpName}` : cpName
  return receiver;
}

export const getEmailTemp = (row = {}) => {
  // console.log(row.status)
  const { cp_name, label_revenue, label_array, start_date, end_date, status } = row;
  let labelsArray = JSON.parse(label_array)
  // console.log(labelsArray.length)
  // labelsArray = []
  const labelsHTML = (labelsArray || []).map((item, index) => {
    let grossRevenue = item.grossRevenue || 0
    grossRevenue = grossRevenue && grossRevenue.toFixed(2)
    let labelRevenue = item.labelRevenue || 0
    labelRevenue = labelRevenue && labelRevenue.toFixed(2);
    let labelShare = parseInt((item.label_share) * 100)
    return (
      `
      <tr>
        <td style="width:20%" >
          <span style="color: #24242C;display: block;padding-right: 15px;">${item.label_name}</span>
        </td>
        <td style="width:25%" >
          <span style="color: #24242C;display: block;padding-right: 15px;">${getFormattedDate(start_date)} <b>To</b> ${getFormattedDate(end_date)}</span>
        </td>
        <td style="width:20%" >
          <span style="color: #24242C;display: block;padding-right: 15px;"><b>PKR</b> ${commaSeparateValue(parseFloat(grossRevenue))}</span>
        </td>
        <td style="width:15%" >
          <span style="color: #24242C;display: block;padding-right: 15px;">${labelShare}%</span>
        </td>
        <td style="width:20%" >
          <span style="color: #24242C;display: block;padding-right: 15px;"><b>PKR</b> ${commaSeparateValue(parseFloat(labelRevenue))}</span>
        </td>
      </tr>
      `
    )
  }).join('')

  // const status = row.status === 4 ? 'Hold' : row.status === 6 && 'Rejected'
  const statusObj = getStatusColor(row.status);
  const statusText = statusObj.statusText;
  const receiver = getEmailReceiver(row.status, cp_name)// return receiver name e.g dear Finance team etc

  const html =
    `
    <html>
      <body style="margin:0px; padding: 0px 0px" >
        <div style="max-width: 800px; margin: 0 auto;" >
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap');
          </style>
          <table  width="100%"  border="0" cellspacing="0" cellpadding="0"  style="font-family: 'Roboto Condensed', sans-serif;font-size: 14px; font-weight: 400; ">
            <tr align="center">
              <td >
                <span style="color: #ff0000;line-height: 34px;">NOTE: THIS IS AN AUTOMATED MESSAGE - PLEASE DO NOT REPLY DIRECTLY TO THIS EMAIL.</span>
              </td>
            </tr>
            <tr>
              <td>
                ${(status != 4 && status != 5 && status != 7 && status != 10) ?

      `<table  width="100%"  border="0" cellspacing="0" cellpadding="0" style="padding-left:25px;padding-right:25px;margin-top: 30px;margin-bottom: 90px;"  >
                    <tr>
                      <td>
                        <table width="100%"   border="0" cellspacing="0" cellpadding="0" style="background:#F8F8F8;padding:30px 25px 80px; border-radius: 25px; " >
                          <tr>
                            <td>
                              <div>
                              <h1 style=" background-color: #5478FA; color: #fff; width: fit-content; padding: 6px; border-radius: 10px">CP-RRP</h1>
                              </div>   
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h2 style=" font-size: 26px;font-weight: 700;color: #000000;margin-bottom: 10px;">Dear ${receiver},</h2>
                              <span style=" font-size: 20px; color: #000000;line-height: 25px;">
                              ${(status == 1) ? `Payment budget to be prepared as per the decided term.`
        :
        (status == 2) ? `Budget approved by Finance for the following cp </br><span>${cp_name}</span>`
          :
          (status == 3) ? `<p style='color:red ;background:#efdbdc;border-radius:4px; padding: 3px 5px'>Budget is not approved by Finance Team due to following reason:</br><span>${cp_name}</p>`
            :
            (status == 6) ? `Invoice Validation of the Uploaded Invoice is failed due to following reason:`
              :
              (status == 8) ? `Payment Processing of the invoices received is on <b>Hold</b> due to following reason:`
                :
                (status == 9) ? `To be put on hold in case of objection with comments due to following reason:`
                  :
                  (status == 11) ? `Incorrect Invoice to be marked <b>Rejected</b> due to following reason:`
                    :
                    ``
      }
                                        </span>
                                  </td>
                                </tr>
                                ${(status == 3 || status == 6 || status == 8 || status == 9 || status == 11) ?
        `<tr>
                                    <td >
                                      <ul style="padding: 0px; margin-top:10px">
                                          <li style="margin-bottom: 15px;">
                                            <span style=" font-size: 16px;  color: #000000;">${row?.reason}</span>
                                          </li>
                                      </ul>
                                    </td>
                                  </tr>`
        :
        ``
      }
                        </table>
                      </td>
                    </tr>
                  </table>`
      :
      `
                  <table  width="100%"  border="0" cellspacing="0" cellpadding="0"   >
	                 <tr>
	                   	<td >
		                	  <table width="100%"  align="center"   border="0" cellspacing="0" cellpadding="0" style="padding-bottom:30px;" >
		                  	  <tr align="center">
			               		    <td>
					               	    <div>
						                    <img src="images/Mask Group 1.png" alt="" border="0" style="width:100%" >
					              	    </div>	
                            </td>
                          </tr>
                          <tr align="center">
                            <td style="padding-top:30px;">
                              <div>
                                <h1 style="  background-color: #5478FA; color: #fff; width: fit-content; padding: 6px; border-radius: 10px">CP-RRP</h1>
                              </div>
                            </td>
                          </tr>
                          <tr align="center">
                            <td >
                              <h2 style=" font-size: 26px;font-weight: 700;color: #000000;margin-bottom: 10px;">Dear ${cp_name}</h2>
                            </td>
                          </tr>
                          ${(status == 4) ?
                                  `<tr align="center">
                                    <td >
                                      <span style=" font-size: 20px; color: #000;line-height: 34px;">Kindly upload the invoice into the CP-RRP portal amounting to the total of <br> <span style=" font-weight: 700;color: #36ba80;padding: 0px 8px; background:#e1f5ec;border-radius:4px;font-size: 22px;">PKR ${commaSeparateValue(parseFloat(label_revenue.toFixed(2)))} </span>has for your share.</span>
                                    </td>
                                  </tr>
                                  <tr align="center">
                                    <td style="padding-top:2px" >				   
                                      <span style=" font-size: 17px; color: #000;line-height: 25px;padding: 0px 5px; background:#f6e9b5;border-radius:4px">(Please send us the signed and scanned copy.)</span></span>
                                    </td>
                                  </tr>
                                  <tr align="center">
                                  <td >
                                    <h3 style=" font-size: 22px;font-weight: 700;color: #000000;margin-bottom: 10px;">BILL TO:</h3>
                                  </td>
                                </tr>
                                <tr align="center">
                                  <td >
                                    <p style=" font-size: 22px; color: #000000;margin-bottom: 10px; margin-top:0px;line-height: 35px;">Finance Team<br>Rockville Building, Plot # 67 & 82, Business Square, Gulberg Greens<br/>Islamabad<br/>Phone No: +92 51 8438284<br/>NTN: 1887271-9</p>
                                  </td>
                                </tr>
                                  `
                              :
                               (status == 10) ?
                                    `
                                    <tr align="center">
                                    <td >
                                    <span style=" font-size: 20px; color: #000;line-height: 28px;">The invoice amounting of a total  <span style=" font-weight: 700;color: #36ba80;padding: 0px 8px; background:#e1f5ec;border-radius:4px;font-size: 22px;">PKR ${commaSeparateValue(parseFloat(label_revenue.toFixed(2)))}</span><br>has been cleared.</span></td>
                                </tr>
                                    `
                                :
                                  `
                                  <tr align="center">
                                  <td >
                                    <span style=" font-size: 20px; color: #000;line-height: 28px;">The invoice uploaded amounting to the total of has been approved  <span style=" font-weight: 700;color: #36ba80;padding: 0px 8px; background:#e1f5ec;border-radius:4px;font-size: 22px;">PKR ${commaSeparateValue(parseFloat(label_revenue.toFixed(2)))}</span><br> and forwarded to the Finance Department for processing.</span>                              </td>
                                </tr>`
                              

                          }

                        </table>
                      </td>
                    </tr>

                  </table>
                  <table   width="100%"    border="0" cellspacing="0" cellpadding="0" style="background:#f5fbf9" >
                    <tr>
                      <td>
                        <table width="100%"  cellspacing="0" cellpadding="0" border="0" style="padding: 13px 0px; " >
                          <tbody>
                            <tr>
                              <td>
                                <table width="100%"  cellspacing="0" cellpadding="0" border="0" style="padding: 6px 30px; " >
                                  <tbody>
                                    <tr>
                                      <td style="width:20%" >
                                        <span style="    color: #36BA80;display: block;padding-right: 15px;">LABEL</span>
                                      </td>
                                      <td style="width:25%" >
                                        <span style="    color: #36BA80;display: block;padding-right: 15px;">DURATION</span>
                                      </td>
                                      <td style="width:20%" >
                                        <span style="    color: #36BA80;display: block;padding-right: 15px;">GROSS REVENUE</span>
                                      </td>
                                      <td style="width:15%" >
                                        <span style="    color: #36BA80;display: block;padding-right: 15px;">LABEL SHARE</span>
                                      </td>
                                      <td style="width:20%" >
                                        <span style="    color: #36BA80;display: block;padding-right: 15px;">LABEL REVENUE</span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <table width="100%"  cellspacing="0" cellpadding="0" border="0" style="padding: 6px 30px; " >
                                  <tbody>
                                    <tr>
                                      <td style="width:100%" >
                                        <div style="height:1px; width:100%;background:rgba(89, 198, 150,0.06)"></div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <table width="100%"  cellspacing="0" cellpadding="0" border="0" style="padding: 6px 30px; " >
                                  <tbody>
                                    ${labelsHTML}
                                  </tbody>
                                </table>
                                <table   width="100%"    border="0" cellspacing="0" cellpadding="0" style="background:#59c696" >
                                  <tr>
                                    <td>
                                      <table width="100%"  cellspacing="0" cellpadding="0" border="0" style="padding: 13px 30px; " >
                                        <tbody>
                                          <tr>
                                            <td style="width:80%" >
                                              <span style="  line-height: 20px;  color: #000000"><span style="color:#fff; padding-right:7px"><b>PKR</b></span> ${convertNumberToWords(parseInt(label_revenue))}</span>
                                            </td>
                                            <td style="width:20%">
                                              <span style="line-height: 20px; font-weight: 500;color: #fff"><b>PKR ${commaSeparateValue(parseFloat(label_revenue.toFixed(2)))}</b></span>
                                            </td>
                                          </tr>      
                                        </tbody>
                                      </table> 
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>        
                      </td>
                    </tr>
                  </table>
    
                  ${(status == 4) ?
        `
                            <table   width="100%"  border="0" cellspacing="0" cellpadding="0" style="padding: 25px 30px 0px;" >
                            <tr>
                              <td>
                                <table width="100%"  cellspacing="0" cellpadding="0" border="0" style="padding: 0px 0px 10px; " >
                                  <tbody>
                                    <tr>
                                      <td style="width:auto; display:inline-block;margin-right:15px; padding-bottom:14px;">
                                        <span style="position: relative;top: 15px; font-size: 12px;font-weight: 700;color: #36BA80;">BANK NAME:</span>
                                      </td>
                                      <td style="margin-right:15px;padding-bottom:14px;display:inline-block;min-width:45%;background:#f5fcf9; border-radius:4px; padding: 5px 5px; min-height: 14px;">
                                        <span style="   color: #000;"></span>
                                      </td>
                                    </tr>
                                    <tr>
                                        <td style="width:auto; display:inline-block;margin-right:15px; padding-bottom:14px;">
                                          <span style="position: relative;top: 15px; font-size: 12px;font-weight: 700;color: #36BA80;">ACCOUNT TITLE:</span>
                                        </td>
                                        <td style="margin-right:15px;padding-bottom:14px;display:inline-block;min-width:40%;background:#f5fcf9; border-radius:4px; padding: 5px 5px; min-height: 14px;">
                                          <span style=" font-size: 12px; color: #000;"></span>
                                        </td>
                                        <td style="width:auto; display:inline-block;margin-right:15px; padding-bottom:14px;">
                                          <span style="position: relative;top: 15px; font-size: 12px;font-weight: 700;color: #36BA80;">BRANCH CODE:</span>
                                        </td>
                                        <td style="padding-bottom:14px;display:inline-block;min-width:18%;background:#f5fcf9; border-radius:4px; padding: 5px 5px; min-height: 14px;">
                                          <span style=" font-size: 12px; color: #000;"></span>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style="width:auto; display:inline-block;margin-right:15px; padding-bottom:14px;">
                                          <span style="position: relative;top: 15px; font-size: 12px;font-weight: 700;color: #36BA80;">ACCOUNT NUMBER:</span>
                                        </td>
                                        <td style="margin-right:15px;padding-bottom:14px;display:inline-block;min-width:45%;background:#f5fcf9; border-radius:4px; padding: 5px 5px; min-height: 14px;">
                                          <span style=" font-size: 12px; color: #000;"></span>
                                        </td>
                                          <td style="width:auto; display:inline-block;margin-right:15px; padding-bottom:14px;">
                                            <span style="position: relative;top: 15px; font-size: 12px;font-weight: 700;color: #36BA80;">NTN:</span>
                                          </td>
                                          <td style="padding-bottom:14px;display:inline-block;min-width:20%;background:#f5fcf9; border-radius:4px; padding: 5px 5px; min-height: 14px;">
                                            <span style=" font-size: 12px; color: #000;"></span>
                                          </td>
                                        </tr>	
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </table>
                                      <table   width="100%" align="center"   border="0" cellspacing="0" cellpadding="0" >
                                        <tr>
                                          <td >
                                            <table align="center" cellspacing="0" cellpadding="0" border="0" style="padding: 25px 30px 0px; " >
                                              <tbody>
                                                <tr align="center">
                                                  <td >
                                                    <span style=" font-size: 16px; color: #000;line-height: 25px;padding: 0px 5px; background:#ffecc7;border-radius:4px">Note: Payment will only be released to the account with whom the contract is signed. Rockville will not make any payment to any other individual or any 3rd party bank account.</span>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      </table>
                                      `
        :
        ``
      }
                                    <table   width="100%" align="center"   border="0" cellspacing="0" cellpadding="0" >
                                      <tr>
                                        <td>
                                          <table align="center" cellspacing="0" cellpadding="0" border="0" style="padding: 25px 30px; " >
                                            <tbody>
                                              <tr align="center">
                                                <td >
                                                  <span style=" font-size: 13px;line-height: 20px; font-weight: 300;color: #000000">Taxes are deducted on the invoice as per the applicable rules of FBR. A tax deductions certificate will be provided as per law. As per the current applicable rates defined by FBR the Withholding tax applicable on the invoice of an active filer, i.e. (Pvt.) LTD companies are 8%, and the tax applicable on individuals and Aops is 10%. In the case of non-filer/In-active filer, the tax deducted will be 16% and 20% respectively.</span>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </table>
                                    `
    }
                <!-- start footer -->
                <table   width="100%" align="center"   border="0" cellspacing="0" cellpadding="0" style="background: #F8F8F8;">
                  <tr>
                    <td>
                      <table align="center" cellspacing="0" cellpadding="0" border="0" style="padding-bottom: 10px; padding-top:20px; " >
                        <tbody>
                          <tr align="center">
                            <td >
                            <a href="https://rockvilletech.com/" style ="text-decoration: none"><h2 align="center"  style="margin: 0px; color: #5478FA">Rockville Technologies (Pvt.) Ltd</h2></a>
                              <span style=" font-size: 11px;  color: #000000">Rockville Building, Plot # 67 & 82, Business Square, Gulberg Greens<br/>Islamabad<br/>Phone No: +92 51 8438284</span>
                            </td>
                          </tr>
                          <tr align="center">
                            <td style="padding-top:10px; padding-bottom:10px;display: inline-block;margin-right:15px;">
                              <a style="text-decoration: none ;color: #000000" href="https://cpreports.rockvillegroup.com" style="font-family: 'Roboto', sans-serif;;font-size: 10px; align="center" color: #000000">© ${new Date().getFullYear()} <span style="color:#36ba80">CP-RRP</span> - All rights reserved  </a>
                            </td>
                            <td style="padding-top:10px; padding-bottom:10px;display: inline-block;">
                              <span style="font-family: 'Roboto', sans-serif;text-decoration: none;font-size: 10px;  color: #000000"></span>
                            </td>
                            <td style="padding-top:10px; padding-bottom:10px;display: inline-block;">
                           <a href="#" style="font-family: 'Roboto', sans-serif;text-decoration: none;font-size: 10px;  color: #000000"></a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </table>
                <!-- end footer -->
              </td> 
            </tr>  
          </table>   
        </div> 
      </body>
    </html>
  `
























  // const html = `
  //         <html><body>
  //          <h2>Dear <b>${receiver}</b> </h2>
  //           ${(status == 1) ? `<div>
  //     <p>Payment budget to be prepared as per the decided term.</p>
  //   </div>` :
  //   (status == 2) ? `<div>
  //     <p>Budget to be approved by Finance for Payments.</p>    </div>` :
  //     (status == 3) ? `<div>
  //     <p style='color:red;'>Budget is not approved by Finance for Payments due to following reason</p> </p>
  //     <p style='color:red;'>${row?.reason} </p> </div>` :
  //       (status == 5) ? `<div>
  //     <p> Uploaded Invoice is valid so proceed payment processing</p> </div>` :
  //         (status == 6) ? `<div>
  //     <p>Invoice Validation of the Uploaded Invoice is failed due to following reason:</p>
  //     <p style='color:red;'>${row?.reason} </p> </div>` :
  //           (status == 7 || status == 10) ? `<div>
  //        <p>The invoice amounting to a total of <b>PKR ${label_revenue.toFixed(2)} </b> has been cleared . </p>
  //     <p>BILL TO:<br/>Finance Team<br/><h2>Rockville Technologies (Pvt) Ltd<br/>Rockville Building, Plot 67 & 82<br/>
  //       Business Square, Gulberg Greens, Islamabad<br/>Phone No: 051- 8438284<br/>NTN: 1887271-9 <br/></h2>
  //     </p>

  //     <table width="80%" border="1">
  //       <thead>
  //       <tr>
  //         <th>Label</th>
  //         <th>Duration</th>
  //         <th>Gross revenue</th>
  //         <th>Label share</th>
  //         <th>Label revenue</th>
  //         <th>Description</th>
  //       </tr>
  //       </thead>
  //       <tbody>
  //       ${labelsHTML}
  //       </tbody>
  //       <tr>
  //         <td style='padding=10px;text-transform: capitalize;' colSpan="4"><b>PKR</b>
  //           ${convertNumberToWords(parseInt(label_revenue.toFixed(2)))}</td>
  //         <td style="padding=10px"><b>PKR</b> ${label_revenue.toFixed(2)}</td>
  //       </tr>
  //     </table>
  //       <br/>
  //     <p>Taxes are deducted on the invoice as per the applicable rules of FBR. A tax deductions certificate
  //       will be provided as per law. As per the current applicable rates defined by FBR, the Withholding tax
  //       applicable on the invoice of an active filer, i.e. (Pvt.) Ltd companies are 8%, and the tax applicable
  //       on individuals and AoPs is 10%. In the case of non-filer/ In-active filer, the tax deducted will be 16%
  //       and 20% respectively.</p>
  //   </div>` :
  //             (status == 8) ? `<div>
  //     <p style='color:red;'>Payment Processing of the invoices received is on <b>Hold</b> due to following reason:</p>
  //     <p style='color:red;'>${row?.reason} </p> </div>` :
  //               (status == 9) ? `<div>
  //     <p style='color:red;'>To be put on hold in case of objection with comments due to following reason:</p>
  //     <p style='color:red;'>${row?.reason} </p> </div>` :
  //                 (status == 11) ? `<div>
  //     <p style='color:red;'>Incorrect Invoice to be marked <b>Rejected</b> due to following reason:</p>
  //     <p style='color:red;'>${row?.reason} </p> </div>` :
  //                   ([4, 5,].includes(row.status)) &&
  //                   `<div><p>Kindly raise the invoice amounting to a total of <b>PKR ${label_revenue.toFixed(2)}</b> for
  //     your share. Please send us the signed and scanned copy. </p>
  //     <p>BILL TO:<br/>Finance Team<br/>Rockville Technologies (Pvt) Ltd<br/>Rockville Building, Plot 67 & 82<br/>
  //       Business Square, Gulberg Greens, Islamabad<br/>Phone No: 051- 8438284<br/>NTN: 1887271-9 <br/>
  //     </p>
  //     <table width="80%" border="1">
  //       <thead>
  //       <tr>
  //         <th>Label</th>
  //         <th>Duration</th>
  //         <th>Gross revenue</th>
  //         <th>Label share</th>
  //         <th>Label revenue</th>
  //         <th>Description</th>
  //       </tr>
  //       </thead>
  //       <tbody>
  //       ${labelsHTML}
  //       </tbody>
  //       <tr>
  //         <td style='padding=10px;text-transform: capitalize;' colSpan="4"><b>PKR</b>
  //           ${convertNumberToWords(parseInt(label_revenue.toFixed(2)))}</td>
  //         <td style="padding=10px"><b>PKR</b> ${label_revenue.toFixed(2)}</td>

  //       </tr>
  //     </table>
  //     <p style="background-color:orange;width: fit-content; padding: 10px ">Bank Name:
  //       Account Title:<br/>
  //       Account Number:<br/>
  //       Branch Code:<br/>
  //       NTN:
  //     </p>
  //     <p>Please mention the above-highlighted information on the invoice as the payment will be made via pay
  //       order so this information needs to be correct</p>
  //     <p>
  //       <b>Note: Payments will only be released to the account with whom the contract is signed. Rockville will
  //         not make any payments to any other individual or any 3rd party bank account.</b></p>
  //     <p>Taxes are deducted on the invoice as per the applicable rules of FBR. A tax deductions certificate
  //       will be provided as per law. As per the current applicable rates defined by FBR, the Withholding tax
  //       applicable on the invoice of an active filer, i.e. (Pvt.) Ltd companies are 8%, and the tax applicable
  //       on individuals and AoPs is 10%. In the case of non-filer/ In-active filer, the tax deducted will be 16%
  //       and 20% respectively.</p>
  //   </div>`
  //   // :`<div>
  //   // <p style='color:red;'>To be put on hold in case of objection with comments due to following reason:</p>
  //   // <p style='color:red;'>${row?.reason} </p> </div>`

  // } 
  //          </body></html>`
  return { html, statusText }
}
// <p>Label Revenue: PKR ${rows[i].labelRevenue}</p>
// <p>Gross Ravenue: PKR ${rows[i].grossRevenue}</p>

const convertNumberToWords = (number) => {
  var a = ['', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ', 'ten ', 'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ', 'eighteen ', 'nineteen '];
  var b = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

  function inWords(num) {
    if ((num = num.toString()).length > 9) return 'overflow';
    let n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return;
    var str = '';
    str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
    str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
    str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
    str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
    str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + 'only ' : '';
    return (str.charAt(0).toUpperCase() + str.slice(1));
  }

  // document.getElementById('number').onkeyup = function () {
  //   document.getElementById('words').innerHTML = inWords(document.getElementById('number').value);
  // };
  return inWords(number)
}

const pending = 'Pending';
const budgetAwaiting = 'Budgeting-Payment';
const awaitingBudgetApprovalSuccess = 'Awaiting-Budget-Approval(Success)';
const awaitingBudgetApprovalFailure = 'Awaiting-Budget-Approval(Failure)';
const invoicingEmailSuccess = 'Rockville Revenue Clearance';
const invoicingEmailFailure = 'Invoicing-Email (Failure)';
const pendingInvoiceApprovalSuccess = 'Invoice Approved for Processing';
const pendingInvoiceApprovalFailure = 'Pending-Invoice (Failure)';
const pendingClearanceFromFinanceSuccess = 'Pending-Clearance-Finance (Invoice Cleared)';
const pendingClearanceFromFinanceFailure = 'Pending-Clearance-Finance (Failure)';
const onHoldByFinance = 'Hold-By-Finance';
const invoiceCleared = 'Invoice-Cleared';
const invoiceRejected = 'Invoice-Rejected';

export const getStatusColor = (value) => {
  let [statusText, statusColor, textColor] = value == 1 ? [budgetAwaiting, ' bg-red', 'color-red'] :
    value == 2 ? [awaitingBudgetApprovalSuccess, 'bg-orange', 'color-orange'] :
      value == 3 ? [awaitingBudgetApprovalFailure, 'bg-red', 'color-red'] :
        value == 4 ? [invoicingEmailSuccess, ' bg-green', ' color-green'] :
          // value == 5 ? [invoicingEmailFailure, ' bg-red', ' color-red'] :
          value == 5 ? [pendingInvoiceApprovalSuccess, ' bg-green', ' color-green'] :
            value == 6 ? [pendingInvoiceApprovalFailure, ' bg-red', ' color-red'] :
              value == 7 ? [pendingClearanceFromFinanceSuccess, ' bg-green', ' color-green'] :
                value == 8 ? [pendingClearanceFromFinanceFailure, ' bg-red', ' color-red'] :
                  value == 9 ? [onHoldByFinance, ' bg-red', ' color-red'] :
                    value == 10 ? [invoiceCleared, ' bg-green', ' color-green'] :
                      value == 11 ? [invoiceRejected, 'bg-red', ' color-red'] : [budgetAwaiting, 'bg-red', ' color-red']

  let obj = { statusText, statusColor, textColor }
  return (obj)
}

export const getCPStatusColor = (value) => {
  let [statusText, statusColor, textColor] = (value == 1 || value == 2 || value == 3) ? [pending, ' bg-red', 'color-red'] :
    // value == 2 ? [awaitingBudgetApprovalSuccess, 'bg-orange', 'color-orange'] :
    //   value == 3 ? [awaitingBudgetApprovalFailure, 'bg-red', 'color-red'] :
    value == 4 ? [invoicingEmailSuccess, ' bg-green', ' color-green'] :
      // value == 5 ? [invoicingEmailFailure, ' bg-red', ' color-red'] :
      value == 5 ? [pendingInvoiceApprovalSuccess, ' bg-green', ' color-green'] :
        value == 6 ? [pendingInvoiceApprovalFailure, ' bg-red', ' color-red'] :
          value == 7 ? [pendingClearanceFromFinanceSuccess, ' bg-green', ' color-green'] :
            value == 8 ? [pendingClearanceFromFinanceFailure, ' bg-red', ' color-red'] :
              value == 9 ? [onHoldByFinance, ' bg-red', ' color-red'] :
                value == 10 ? [invoiceCleared, ' bg-green', ' color-green'] :
                  value == 11 ? [invoiceRejected, 'bg-red', ' color-red'] : [budgetAwaiting, 'bg-red', ' color-red']

  let obj = { statusText, statusColor, textColor }
  return (obj)
}
export const getTwoStatusColor = (status) => {
  let [statusText, statusColor] = status == 1 ? ['active', ' status-active'] : ['in-active', 'status-inactive'];
  return { statusText, statusColor }
}

export const getTwoProcessedColor = (processed) => {
  let [processedText, processedColor] = processed == 1 ? ['Done', ' color-green'] : ['Pending', 'color-red'];
  return { processedText, processedColor }
}

export const getContStatusColor = (endDate) => {
  let currentDate = getFormattedDate(new Date());

  let [contStatusText, contStatusColor] = (endDate >= currentDate || !endDate) ? ['valid', ' color-green'] : ['expired', 'color-red'];
  return { contStatusText, contStatusColor }
}

// poc 2 not required
