import React, { useEffect, useState } from 'react';
import { Dashboard, Loader } from "../../components";
import * as actions from "../../store/actions";
import { connect } from "react-redux";

function Index(props) {
  const [year, setYear] = useState('')
  const [month, setMonth] = useState('')

  let {
    dashboardReducer: { dashboard, loading, error }
  } = props;

  useEffect(() => {
    // fetchScanGraphData();
    fetchScanPieGraphData();
  }, []);

  const fetchScanGraphData = (data) => {
    props.getScanGraphDataStart(data);
  }

  const fetchScanPieGraphData = (data) => {
    props.getScanPieGraphStart(data);
  }

  return (
    <div className='mb-20'>
      <Dashboard loading={props.loading} scanGraphData={props.scanGraphData} pieChartData={props.scanPieGraphData} dountChartData={props.scanPieGraphData} dataLocations={props.scanPieGraphData} fetchScanGraphData={fetchScanGraphData} />
    </div>
  );
}


const mapStateToProps = ({ dashboardReducer = {}, servicesReducer = {}, recordReducer }) => {
  const { loading, error, success, scanGraphData, scanPieGraphData } = recordReducer;
  return { dashboardReducer, servicesReducer, loading, error, success, scanGraphData, scanPieGraphData };
};
const mapDispatchToProps = (dispatch) => {
  return {
    // getDashboardStart: (data) => dispatch(actions.dashboardStart(data)),
    // messageHandler: (data) => dispatch(actions.labelsMessageHandler(data)),
    // confirmationHandler: (data) => dispatch(actions.confirmationHandler(data)),
    // getServicesList: (data) => dispatch(actions.getServicesList(data)),
    // serviceReportMessageHandler: (data) => dispatch(actions.serviceReportMessageHandler(data)),

    // setUserServicesList: (data) => dispatch(actions.setUserServicesList(data)),
    // setCategoryList: (data) => dispatch(actions.setCategoryList(data)),
    getScanGraphDataStart: (data) => dispatch(actions.getScanGraphDataStart(data)),
    getScanPieGraphStart: (data) => dispatch(actions.getScanPieGraphStart(data)),

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);
