import HttpService from "./http.service";

class ServiceService extends HttpService {
    cpServicesList = (params) => this.get("cp_service/list", params);

    createCpService = (params) => this.post("cp_service/store", params);

    servicesList = (params) => this.get("service/list", params);

    servicesData = (params) => this.get("cons_report/service_data", params);

    create = (data) => this.post("suppliers/drivers", data);

    show = (data) => this.get("suppliers/drivers", data);

    update = (data, params) => this.put("suppliers/drivers");
}

export default new ServiceService();
