import './App.css';
import './assets/index.scss'
import Routes from "./routes/routes";
import {Provider} from 'react-redux';
import PersistedStore from "./store";

const store = PersistedStore.getDefaultStore().store;

const App = () => {

  return (
    <Provider store={store}>
      <Routes/>
    </Provider>)
}

export default App;

// https://www.codementor.io/blog/react-optimization-5wiwjnf9hj#.YbD42UeP0l4.linkedin
