import React from 'react';
import Typography from "@material-ui/core/Typography";
import SearchBar from "../../search";
import { DownloadAddButton } from '../../index'
import { Add } from '@material-ui/icons';
import FormControl from '@material-ui/core/FormControl';
import { Select, MenuItem } from "@material-ui/core";
import InputLabel from '@material-ui/core/InputLabel';
import './styles.scss';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';


function Index(props) {

  const {
    tableLabel, isLink, showFilter, setCountry, country, maifestSelectData, countryData, manifestID, setManifestID, searchValue, setSearchValue, newIconHandler, showSearch = true, showAdd = true,
    exportData, searchPlaceholder = 'search...', downloadHandler,
    className, styles, loading, fileName
  } = props;

  const [value, setValue] = React.useState('');
  const [countryValue, setCountryValue] = React.useState(country ? country : '');

  const handleChange = (value) => {
    setValue(value)
    if (value) {
      setManifestID(value._id)
    }
    else {
      setManifestID('')
    }
  };

  const handleChangeCountry = (value) => {
    setCountryValue(value)
    if (value) {
      setCountry(value)
    }
    else {
      setCountry('')
    }
  };

  const filterOptions = (options, { inputValue }) => {
    if (Array.isArray(options)) {
      const inputValueLowerCase = inputValue.toLowerCase();
      return options.filter(option =>
        (option.title && option.title.toLowerCase().includes(inputValueLowerCase)) ||
        (option.file && option.file.filename.toLowerCase().includes(inputValueLowerCase))
      ).slice(0, 10); // Display up to 10 matching options
    }
  };


  const filterCountry = (options, { inputValue }) => {
    const inputValueLowerCase = inputValue.toLowerCase();
    return options.filter((option) => !/[^A-Za-z0-9\x00-\x7F().,]+$/.test(option) && option.toLowerCase().includes(inputValueLowerCase));
  };
  
  return (
    <div className="table-title-container display-flex full-width" style={styles}>
      <Typography variant='h4' className='table-title-graph'>
        {tableLabel}
      </Typography>

      {
        isLink &&
        <a href='https://atlasguard.s3.us-east-1.amazonaws.com/Manifest/652e836375413ebf84856acc/20231017T125147238Z-Falcon-147_19-OCT-23_KBL-TIA----FINAL-MANIFEST.XLSX'>Download Sample File</a>
      }
      {
        showFilter &&
        <div className='filter'>
          <div className='manifest-filter'>
            <Autocomplete
              className="control-input-dashbox qscan-control-input"
              id="combo-box-demo"
              // options={maifestSelectData || []}
              options={(maifestSelectData && Array.isArray(maifestSelectData)) ? maifestSelectData : []}
              value={value}
              onChange={(event, newValue) => {
                handleChange(newValue);
              }}
              filterOptions={filterOptions}
              getOptionLabel={(item) => item.title || item.file?.filename || ''}
              style={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Select Manifest" variant="outlined" />}
            />
            {/* <FormControl variant="outlined" className="control-input-dashbox qscan-control-input">
            <InputLabel id="demo-simple-select-outlined-label">Select Manifest</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={manifestID}
              onChange={handleChange}
              // label="Select Manifest"
            >
              <MenuItem value="" >
                  None
              </MenuItem>
              {maifestSelectData?.map((item) => (
                <MenuItem key={item} value={item._id}>
                    {item.title?item.title:item.file?.filename}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
          </div>
          <div>
            <Autocomplete
              className="control-input-dashbox qscan-control-input"
              id="combo-box-demo"
              options={countryData || []}
              value={countryValue}
              onChange={(event, newValue) => {
                handleChangeCountry(newValue);
              }}
              filterOptions={filterCountry}
              getOptionLabel={(item) => item?item:''}
              style={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Select Country" variant="outlined" />}
            />
            {/* <FormControl variant="outlined" className="control-input-dashbox qscan-control-input">
            <InputLabel id="demo-simple-select-outlined-label">Select Country</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={country}
                onChange={handleChangeCountry}
                // label="Select Manifest"
              >
                <MenuItem value="" >
                    None
                </MenuItem>
                {countryData?.map((item) => (
                  <MenuItem key={item} value={item}>
                      {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
          </div>
        </div>
      }
      {(searchValue != null || showAdd) && <div className='search-bar-container'>
        {showSearch && <SearchBar value={searchValue} onChange={setSearchValue} placeholder={searchPlaceholder} />}
        {showAdd &&
          <DownloadAddButton addHandler={newIconHandler} downloadHandler={downloadHandler} data={exportData} loading={loading} fileName={fileName} />}
        {/* {exportData && <ExportToCSV data={exportData}/>} */}
        {/* {showAdd && <Add className='add-icon mouse-pointer' titleAccess='Add New' onClick={newIconHandler}/>} */}
      </div>}
    </div>
  );
}

export default Index;
