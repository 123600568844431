import React, { useEffect, useState } from 'react';
import Typography from "@material-ui/core/Typography";
import { Table, TableCell, TableRow, Grid, Chip } from "@material-ui/core";
import { CustomSeparator, InputRow, Loader } from "./index";
import Chart from './charts'
import './styles.css'
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import GoogleMap from "../components/modals/map"
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';




const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function Dashboard(props) {
  const classes = useStyles();
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();
  const [year, setYear] = useState(currentYear)
  const [month, setMonth] = useState(currentMonth)
  const [uniqueVisitors, setUniqueVisitors] = useState(0);
  const [country, setCountry] = useState("");
  const [manifest, setManifest] = useState("");
  const [lat, setlat] = useState(33.6192039)
  const [long, setlong] = useState(73.1703513)
  // const currentYear = new Date().getFullYear();
  const years = Array.from({ length: currentYear - 2022 }, (_, index) => 2023 + index);
  const markers = props.pieChartData?.data?.location
  const [value, setValue] = React.useState('');

  // console.log('props.pieChartData?', props.pieChartData)
  // const handleChangeYear = (event) => {
  //   setYear(event.target.value)
  //   props.fetchScanGraphData({ year: event.target.value, month: month, uniqueVisitors: uniqueVisitors,country:country});
  // };
  // const handleChangeMonth = (event) => {
  //   setMonth(event.target.value)
  //   props.fetchScanGraphData({ year: year, month: event.target.value, uniqueVisitors: uniqueVisitors,country:country});
  // };

  useEffect(() => {
    if (year && month)
      props.fetchScanGraphData({ year, month, uniqueVisitors, country, manifest });
  }, [year, month, uniqueVisitors, country, manifest]);

  const [breadCrumbsList, setBreadCrumbsList] = React.useState([
    { text: 'Dashboard', url: '' },
  ]);
  const [user] = useState(localStorage.getItem('user'))
  const showGraph = () => {
    return (<Chart type={1} loading={props.loading} data={props.scanGraphData?.data.graphData} />)
  }

  const showPieGraph = () => {
    return (<Chart type={2} dataPie={props.pieChartData?.data.pieChartData} />)
  }

  const showPieHole = () => {
    return (<Chart type={3} dataDonut={props.pieChartData?.data.donutChartData} />)
  }

  useEffect(() => {
    const fetchCountry = async () => {
      try {
        // https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyAkpc9tUdYPsnpBEVG1OFD7YtZFOGX1GoY
        const response = await fetch('https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyAIPAC1LtBnHkZDAnWUa914ggtqvk02X9M', {
          method: 'POST',
        });
        const data = await response.json();
        const { location } = data;
        setlat(location.lat)
        setlong(location.lng)

      } catch (error) {
        console.error('Error:', error);
      }
    };
    fetchCountry();
  }, []);

  const handleChange = (value) => {
    setValue(value)
    if (value) {
      setManifest(value._id)
    }
    else {
      setManifest('')
    }
  };

  const filterOptions = (options, { inputValue }) => {
    if (Array.isArray(options)) {
      const inputValueLowerCase = inputValue.toLowerCase();
      return options.filter(option =>
        (option.title && option.title.toLowerCase().includes(inputValueLowerCase)) ||
        (option.file && option.file.filename.toLowerCase().includes(inputValueLowerCase))
      ).slice(0, 10); // Display up to 10 matching options
    }
  };

  const filterCountry = (options, { inputValue }) => {
    const inputValueLowerCase = inputValue.toLowerCase();
    return options.filter((option) => !/[^A-Za-z0-9\x00-\x7F().,]+$/.test(option) && option.toLowerCase().includes(inputValueLowerCase));
  };

  return (
    <div className="driver-main-page">
      {/* <CustomSeparator
        breadCrumbsList={breadCrumbsList}
        {...props}
        setBreadCrumbsList={setBreadCrumbsList}
      /> */}

      <div className="datatable-box" style={{ top: '60px' }}>
        <div className='table-title-container display-flex full-width' style={{justifyContent:"right"}}>
          {/* <Typography variant='h4' className='table-title table-title-graph text-capitalize'>Welcome! {user?.name || "Admin"} </Typography> */}
          <div className='date-inputs'>
            <div>
              <FormControl variant="outlined" className="control-input-dashbox">
                <InputLabel id="demo-simple-select-outlined-label">Year</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={year}
                  // onChange={handleChangeYear}
                  onChange={(event) => {
                    setYear(event.target.value);
                  }}
                  label="Year"
                >
                  {years.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

            </div>
            <div>
              <FormControl variant="outlined" className="control-input-dashbox">
                <InputLabel id="demo-simple-select-outlined-label">Month</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={month}
                  // onChange={handleChangeMonth}
                  onChange={(event) => {
                    setMonth(event.target.value);
                  }}
                  label="Month"
                >
                  <MenuItem value={1}>Januray</MenuItem>
                  <MenuItem value={2}>Feburary</MenuItem>
                  <MenuItem value={3}>March</MenuItem>
                  <MenuItem value={4}>April</MenuItem>
                  <MenuItem value={5}>May</MenuItem>
                  <MenuItem value={6}>June</MenuItem>
                  <MenuItem value={7}>July</MenuItem>
                  <MenuItem value={8}>August</MenuItem>
                  <MenuItem value={9}>September</MenuItem>
                  <MenuItem value={10}>October</MenuItem>
                  <MenuItem value={11}>November</MenuItem>
                  <MenuItem value={12}>December</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div>
              <Autocomplete
                className="control-input-dashbox qscan-control-input"
                id="combo-box-demo"
                options={props.scanGraphData?.data.manifestData || []}
                value={value}
                onChange={(event, newValue) => {
                  handleChange(newValue);
                }}
                filterOptions={filterOptions}
                getOptionLabel={(item) => item.title || item.file?.filename || ''}
                style={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Select Manifest" variant="outlined" />}
              />
            </div>
            <div>
              <Autocomplete
                className="control-input-dashbox qscan-control-input"
                id="combo-box-demo"
                options={props.scanGraphData?.data.countryData || []}
                value={country}
                onChange={(event, newValue) => {
                  setCountry(newValue);
                }}
                getOptionLabel={(item) => item ? item : ''}
                filterOptions={filterCountry}
                style={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Select Country" variant="outlined" />}
              />
            </div>
          </div>
        </div>
        {/* {data ? */}
        <div className={'mt-10'}>
          {(props.scanGraphData?.data) ?
            showGraph() : <Loader />
          }
        </div>
        <div className='request' onClick={() => setUniqueVisitors(2)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
            <rect x="0.5" y="0.527344" width="17" height="17" rx="8.5" fill="white" stroke="#17B18F" />
            {uniqueVisitors == 2 && <path d="M13.7759 6.17647C13.5187 5.94118 13.133 5.94118 12.8759 6.17647L8.05446 10.5882L6.06161 8.76471C5.80446 8.52941 5.41875 8.52941 5.16161 8.76471C4.90446 9 4.90446 9.35294 5.16161 9.58824L7.60446 11.8235C7.73304 11.9412 7.86161 12 8.05446 12C8.24732 12 8.37589 11.9412 8.50446 11.8235L13.7759 7C14.033 6.76471 14.033 6.41176 13.7759 6.17647Z" fill="#17B18F" />}
          </svg>
          <span>Request</span>
        </div>
        <div className='unique-visitors' onClick={() => setUniqueVisitors(1)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
            <rect x="0.5" y="0.527344" width="17" height="17" rx="8.5" fill="white" stroke="#EEB80C" />
            {uniqueVisitors == 1 && <path d="M13.7759 6.17647C13.5187 5.94118 13.133 5.94118 12.8759 6.17647L8.05446 10.5882L6.06161 8.76471C5.80446 8.52941 5.41875 8.52941 5.16161 8.76471C4.90446 9 4.90446 9.35294 5.16161 9.58824L7.60446 11.8235C7.73304 11.9412 7.86161 12 8.05446 12C8.24732 12 8.37589 11.9412 8.50446 11.8235L13.7759 7C14.033 6.76471 14.033 6.41176 13.7759 6.17647Z" fill="#EEB80C" />}
          </svg>
          <span>Unique Visitors</span>
        </div>
        <div className='all-requests' onClick={() => setUniqueVisitors(0)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
            <rect x="0.5" y="0.527344" width="17" height="17" rx="8.5" fill="white" stroke="#EEB80C" />
            {uniqueVisitors == 0 && <path d="M13.7759 6.17647C13.5187 5.94118 13.133 5.94118 12.8759 6.17647L8.05446 10.5882L6.06161 8.76471C5.80446 8.52941 5.41875 8.52941 5.16161 8.76471C4.90446 9 4.90446 9.35294 5.16161 9.58824L7.60446 11.8235C7.73304 11.9412 7.86161 12 8.05446 12C8.24732 12 8.37589 11.9412 8.50446 11.8235L13.7759 7C14.033 6.76471 14.033 6.41176 13.7759 6.17647Z" fill="#EEB80C" />}
          </svg>
          <span>Both</span>
        </div>
        {/* : <Loader />} */}
      </div>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <div className="datatable-box" style={{ top: '60px' }}>
            <div className={'mt-10'}>
              <Typography variant='h4' className='table-title-graph text-capitalize'>Request per Country</Typography>
              {(props.pieChartData?.data) ?
                showPieGraph() : <Loader />
              }
            </div>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="datatable-box" style={{ top: '60px' }}>
            <div className={'mt-10'}>
              <Typography variant='h4' className='table-title-graph text-capitalize'>Request per City</Typography>

              {(props.pieChartData?.data) ?
                showPieHole() : <Loader />
              }
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className="datatable-box" style={{ top: '60px' }}>
            <div className={'mt-10'}>
              <Typography variant='h4' className='table-title-graph text-capitalize'>Scan Positions</Typography>
              {/* <p className='map-text'>If a user, who scanned the QR Code provides his location, you will see the positions here</p> */}
              <p className='map-text'>Position of the users who scanned qr code</p>
              <GoogleMap
                markers={markers}
                lat={lat}
                lng={long}
                type={1}
              />
              {/* <Map
                style={{borderRadius:'20px 20px 0px 0px'}}
                containerStyle={{position:'relative',zIndex:1,height:400}}
                google={props.google}
                zoom={2}
                initialCenter={
                  {
                    lat: lat,
                    lng: long
                  }
                }
              >
                {(markers || []).map((marker, index) => (
                  <Marker
                    key={index}
                    position={{ lat: marker.lat, lng: marker.long }}
                  />
                ))}
              </Map> */}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyAIPAC1LtBnHkZDAnWUa914ggtqvk02X9M'
})(Dashboard);
