import * as actionTypes from "../actionTypes";

export const uploadManifestStart = (data) => {
    return {
        type: actionTypes.UPLOAD_MANIFEST_START,
        payload: data,
    };
};
export const uploadManifestSuccess = (data) => {
    return {
        type: actionTypes.UPLOAD_MANIFEST_SUCCESS,
        payload: data.data,
    };
};
export const uploadManifestFailure = (data) => {
    return {
        type: actionTypes.UPLOAD_MANIFEST_FAILURE,
        payload: data,
    };
};
export const manageRecordStart = (data) => {
    return {
        type: actionTypes.MANAGE_RECORD_START,
        payload: data,
    };
};
export const manageRecordSuccess = (data) => {
    return {
        type: actionTypes.MANAGE_RECORD_SUCCESS,
        payload: data,
    };
};
export const manageRecordFailure = (data) => {
    return {
        type: actionTypes.MANAGE_RECORD_FAILURE,
        payload: data,
    };
};

export const getManifestListStart = (data) => {
    return {
        type: actionTypes.GET_MANIFEST_LIST_START,
        payload: data,
    };
};
export const getManifestListSuccess = (data) => {
    return {
        type: actionTypes.GET_MANIFEST_LIST_SUCCESS,
        payload: data.data,
    };
};
export const getManifestListFailure = (data) => {
    return {
        type: actionTypes.GET_MANIFEST_LIST_FAILURE,
        payload: data,
    };
};

export const getRecordsListStart = (data) => {
    return {
        type: actionTypes.GET_RECORDS_LIST_START,
        payload: data,
    };
};
export const getRecordsListSuccess = (data) => {
    return {
        type: actionTypes.GET_RECORDS_LIST_SUCCESS,
        payload: data.data,
    };
};
export const getRecordsListFailure = (data) => {
    return {
        type: actionTypes.GET_RECORDS_LIST_FAILURE,
        payload: data,
    };
}
;
export const uploadImagesStart = (data) => {
    return {
        type: actionTypes.UPLOAD_IMAGES_START,
        payload: data,
    };
};
export const uploadImagesSuccess = (data) => {
    return {
        type: actionTypes.UPLOAD_IMAGES_SUCCESS,
        payload: data.data,
    };
};
export const uploadImagesFailure = (data) => {
    return {
        type: actionTypes.UPLOAD_IMAGES_FAILURE,
        payload: data,
    };
};

export const uploadRecordImageStart = (data) => {
    return {
        type: actionTypes.UPLOAD_RECORD_IMAGE_START,
        payload: data,
    };
};
export const uploadRecordImageSuccess = (data) => {
    return {
        type: actionTypes.UPLOAD_RECORD_IMAGE_SUCCESS,
        payload: data.data,
    };
};
export const uploadRecordImageFailure = (data) => {
    return {
        type: actionTypes.UPLOAD_RECORD_IMAGE_FAILURE,
        payload: data,
    };
};

export const recordMessageHandler = () => {
    return {
        type: actionTypes.RECORD_MESSAGE_HANDLER,
        payload: null,
    };
};

export const scanDataStart = (data) => {
    return {
        type: actionTypes.SCAN_DATA_START,
        payload: data,
    };
};
export const scanDataSuccess = (data) => {
    return {
        type: actionTypes.SCAN_DATA_SUCCESS,
        payload: data,
    };
};
export const scanDataFailure = (data) => {
    return {
        type: actionTypes.SCAN_DATA_FAILURE,
        payload: data,
    };
};

export const getScanListStart = (data) => {
    return {
        type: actionTypes.GET_SCAN_LIST_START,
        payload: data,
    };
};
export const getScanListSuccess = (data) => {
    return {
        type: actionTypes.GET_SCAN_LIST_SUCCESS,
        payload: data.data,
    };
};
export const getScanListFailure = (data) => {
    return {
        type: actionTypes.GET_SCAN_LIST_FAILURE,
        payload: data,
    };
};

export const changePassStart = (data) => {
    return {
        type: actionTypes.CHANGE_PASS_START,
        payload: data,
    };
};
export const changePassSuccess = (data) => {
    return {
        type: actionTypes.CHANGE_PASS_SUCCESS,
        payload: data,
    };
};
export const changePassFailure = (data) => {
    return {
        type: actionTypes.CHANGE_PASS_FAILURE,
        payload: data,
    };
};

export const getScanGraphDataStart = (data) => {
    return {
        type: actionTypes.GET_SCAN_GRAPH_DATA_START,
        payload: data,
    };
};
export const getScanGraphDataSuccess = (data) => {
    return {
        type: actionTypes.GET_SCAN_GRAPH_DATA_SUCCESS,
        payload: data,
    };
};
export const getScanGraphDataFailure = (data) => {
    return {
        type: actionTypes.GET_SCAN_GRAPH_DATA_FAILURE,
        payload: data,
    };
};


export const getManifestDataStart = (data) => {
    return {
        type: actionTypes.GET_MANIFEST_DATA_START,
        payload: data,
    };
};
export const getManifestDataSuccess = (data) => {
    return {
        type: actionTypes.GET_MANIFEST_DATA_SUCCESS,
        payload: data,
    };
};
export const getManifestDataFailure = (data) => {
    return {
        type: actionTypes.GET_MANIFEST_DATA_FAILURE,
        payload: data,
    };
};

export const getScanPieGraphStart = (data) => {
    return {
        type: actionTypes.GET_SCAN_PIE_GRAPH_START,
        payload: data,
    };
};
export const getScanPieGraphSuccess = (data) => {
    return {
        type: actionTypes.GET_SCAN_PIE_GRAPH_SUCCESS,
        payload: data,
    };
};
export const getScanPieGraphFailure = (data) => {
    return {
        type: actionTypes.GET_SCAN_PIE_GRAPH_FAILURE,
        payload: data,
    };
};

export const downloadFileStart = (data) => {
    return {
        type: actionTypes.DOWNLOAD_FILE_START,
        payload: data,
    };
};
export const downloadFileSuccess = (data) => {
    return {
        type: actionTypes.DOWNLOAD_FILE_SUCCESS,
        payload: data.data,
    };
};
export const downloadFileFailure = (data) => {
    return {
        type: actionTypes.DOWNLOAD_FILE_FAILURE,
        payload: data,
    };
};