import { all } from "redux-saga/effects";
import { authSaga } from "./authSagas";
import { dashboardSaga } from "./dashboardSagas";
import { userSaga } from "./userSagas";
import { servicesSaga } from "./serviceSagas";
import { recordSaga } from "./recordSaga";


export function* rootSaga(getState) {
  yield all([
    authSaga(),
    dashboardSaga(),
    userSaga(),
    servicesSaga(),
    recordSaga(),
  ]);
}
