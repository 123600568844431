import React, { useEffect, useState } from 'react';
import '../../assets/scss/scanDetail.scss';
import { Container } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { useStyles } from './styles';
import { mainHeading, dumyImage, dummyImage } from '../../assets/images/img/index';
import * as actions from "../../store/actions";
import { connect } from "react-redux";
import { isMobile } from 'react-device-detect';
import { useParams } from 'react-router-dom';
import { Loader, NoData } from '../../components'
import ScanDetailGerman from "./germanDetail"
import Tooltip from '@material-ui/core/Tooltip';


// const data = [
//     {
//         leftLabel: 'Name',
//         leftValue: 'James Bond',
//     },
//     {
//         leftLabel: 'Passport No',
//         leftValue: '001122334455',
//     },
//     {
//         leftLabel: 'Passport Country',
//         leftValue: 'United Kingdom',
//     },

//     {
//         leftLabel: 'Submission Date',
//         leftValue: '01-02-2023',
//     },
//     {
//         leftLabel: 'Expiration Date',
//         leftValue: '01-10-2023',
//     },
// ];

function CardRow({ leftLabel, leftValue, rightLabel, rightValue }) {
    const isGreen = leftValue === 'approved';
    return (
        <div className='card-div-row'>
            <span className='left-span'>
                {leftLabel}:<br />
                <span className={`inner-text ${isGreen ? 'status-design' : ''}`}>
                    {leftValue}
                </span>
            </span>
        </div>
    );
}
// function padTo2Digits(num) {
//     return num.toString().padStart(2, '0');
// }
// function formatDate(date, months = 1) {
//     console.log('date ', date);
//     return [
//         padTo2Digits(String(date.getDate())),
//         padTo2Digits(String(date.getMonth()) + months),
//         date.getFullYear(),
//     ].join('/');
// }

function ScanDetail(props) {
    const classes = useStyles();
    // const { success, error, loading, scanData } = recordReducer
    const [open, setOpen] = useState(false);
    const [modalImageUrl, setModalImageUrl] = useState('');
    const [osType, setOstype] = useState('')
    const [deviceType, setDeviceType] = useState(isMobile ? 'Mobile' : 'PC')
    const [browser, setBrowserName] = useState('')
    const [lat, setlat] = useState('')
    const [long, setlong] = useState('')
    const [ipAddress, setIPAddress] = useState('')
    const [scanData, setScanData] = useState(null);
    const [scanInfo, setScanInfo] = useState();
    const [country, setCountry] = useState('');
    const [city, setCity] = useState('');
    const [userAgent, setUserAgent] = useState('');
    const [noData, setNoData] = useState(false);
    const { id } = useParams();

    let data = [
        {
            leftLabel: 'Name',
            leftValue: scanInfo?.name,
        },
        {
            leftLabel: scanInfo?.pass_no && new Date(scanInfo?.pass_expire_date)>new Date(scanInfo?.manifest_date) ? 'Passport No' : 'Status',
            leftValue: scanInfo?.pass_no && new Date(scanInfo?.pass_expire_date)>new Date(scanInfo?.manifest_date) ? scanInfo?.pass_no : "Laissez-passer"
        },
        {
            leftLabel: 'Passport Country',
            leftValue: scanInfo?.pass_issuing_country
        },
        {
            leftLabel: 'Submission Date',
            leftValue: scanInfo?.manifest_date_formated
            // leftValue: formatDate(scanInfo?.manifest?.file?.filename)
        },
        {
            leftLabel: 'Expiration Date',
            leftValue: scanInfo?.pass_expire_date
        },
    ];

    useEffect(() => {
        if (scanInfo && scanInfo?.pass_no && new Date(scanInfo?.pass_expire_date)>new Date(scanInfo?.manifest_date)) {
            setScanData(data);
        } else {
            if (!scanInfo) {
                setScanData([]);
            } else {
                const filteredData = data.filter((item, index) => index !== 2 && index !== 4);
                setScanData(filteredData);
            }
        }
    }, [id, scanInfo]);

    const handleDummyImageClick = (imageUrl) => {
        setModalImageUrl(imageUrl);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        const userAgent = window.navigator.userAgent;
        setUserAgent(userAgent)
        const platform = window.navigator.platform;

        if (/Android/i.test(userAgent)) {
            setOstype('Android');
        } else if (/iPhone|iPad|iPod/i.test(userAgent) || /MacIntel/i.test(platform)) {
            setOstype('iOS');
        } else if (/Windows/i.test(userAgent)) {
            setOstype('Windows');
        } else if (/Mac/i.test(userAgent)) {
            setOstype('Mac');
        } else {
            setOstype('Unknown');
        }

        if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
            setBrowserName('Opera');
        } else if (navigator.userAgent.indexOf("Edg") != -1) {
            setBrowserName('Edge');
        } else if (navigator.userAgent.indexOf("Chrome") != -1) {
            setBrowserName('Chrome');
        } else if (navigator.userAgent.indexOf("Safari") != -1) {
            setBrowserName('Safari');
        } else if (navigator.userAgent.indexOf("Firefox") != -1) {
            setBrowserName('Firefox');
        }
        else {
            setBrowserName('unknown');
        }
        // const location = window.navigator && window.navigator.geolocation
        // if (location) {
        //     location.getCurrentPosition((position) => {
        //         setlat(position.coords.latitude)
        //         setlong(position.coords.longitude)
        //     })
        // }
        // else{
        //     setlat('')
        //     setlong('')
        // }
        // console.log("osType",osType)
        // console.log("deviceType",deviceType)
        // console.log("browser",browser)
        // console.log("lat",lat)
        // console.log("long",long)

    }, []);

    useEffect(() => {
        const fetchCountry = async () => {
            try {
                const response = await fetch('https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyAIPAC1LtBnHkZDAnWUa914ggtqvk02X9M', {
                    method: 'POST',
                });
                const data = await response.json();
                const { location } = data;
                setlat(location.lat)
                setlong(location.lng)
                //   if(location.lat && location.long){
                //     console.log("hellooooo")
                //     setlat(location.lat)
                //     setlong(location.long)
                //   }
                //   else{
                //     setlat('')
                //     setlong('')
                //   }
                const countryResponse = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${location.lat},${location.lng}&key=AIzaSyAIPAC1LtBnHkZDAnWUa914ggtqvk02X9M`);
                const countryData = await countryResponse.json();
                const { results } = countryData;
                console.log("country",results[0])
                const countryName = results[0].formatted_address;
                const formattedAddress = countryName.split(", ");

                for (var i = 0; i < results[0].address_components.length; i++) {
                        for (var j = 0; j < results[i].address_components.length; j++) {
                        var component = results[i].address_components[j];
                        if (component.types.includes('locality')) {
                            setCity(component.long_name);
                        }
                        if (component.types.includes('country')) {
                            setCountry(component.long_name);
                        }
                    }
                }
                // setCity(results[0].address_components[3].long_name)
                // setCountry(formattedAddress[formattedAddress.length - 1]);
            } catch (error) {
                console.error('Error:', error);
            }
        };
        fetchCountry();
    }, []);

    useEffect(() => {
        if (props?.success) {
            if (props.scanData) {
                setScanInfo(props.scanData)
            }
            else {
                setNoData(true)
            }
            console.log("setScanInfo", props.scanData)
            setTimeout(() => {
                props.recordMessageHandler()
            }, 1500);
        }
    }, [props?.success]);


    useEffect(() => {
        if (osType !== '' && deviceType !== '' && lat !== '' && long !== '' && browser !== '' && country !== '') {
            let data = {}
            data.record_id = id
            data.lat = lat
            data.long = long
            data.device_type = deviceType
            data.os_type = osType
            data.browser = browser
            data.country = country
            data.user_agent = userAgent
            data.city = city
            console.log("data ", data)
            scanRecord(data)
        }

    }, [osType, deviceType, lat, long, browser, country])

    const scanRecord = (data) => {
        props.scanDetailStart(data);
    }
    return (
        <div>
            {
                (props?.loading) ? <Loader /> : 
                    (scanInfo && !props?.loading) ?
                        (scanInfo.manifest.format_type==1?
                            <div>
                                <Container className='main-container-card-style'>
                                    <Card className='card-style' elevation={2}>
                                        <div>
                                            <div className='align-adjust'>
                                                <img src={mainHeading} style={{ width: '340px' }}/>
                                            </div>
                                            <div className='approved-style'>
                                                <h2>APPROVED</h2>
                                            </div>
                                            <div className='main-ttt-tp'>
                                                <span>Electronic Travel Authorization</span><br></br>
                                                <span>{scanInfo?.a_number}</span>
                                            </div>
                                            <div className='main-image-div'>
                                                <div className='outer-circle'>
                                                    <img className='inner-circle' src={scanInfo?.user_image?.completedUrl}
                                                    />
                                                </div>
                                            </div>
                                            {(scanData).map((item, index) => (
                                                <CardRow
                                                    key={index}
                                                    leftLabel={item.leftLabel}
                                                    leftValue={item.leftValue}
                                                />
                                            ))}
                                        </div>
                                    </Card>
                                </Container>
                            </div>     
                        :
                            <div>
                               <ScanDetailGerman
                                scanInfo={scanInfo}   
                               />
                            </div>
                        )
                        :
                        (noData && !props?.loading) ?
                            <div style={{ marginTop: '120px' }}>
                                <NoData text={"no data available"} />
                            </div>
                            : <Loader />
            }
        </div>
    )
}

const mapStateToProps = ({ recordReducer }) => {
    const { loading, error, success, scanData } = recordReducer;
    return { loading, error, success, scanData };
};
const mapDispatchToProps = (dispatch) => {
    return {
        scanDetailStart: (data) => dispatch(actions.scanDataStart(data)),
        recordMessageHandler: () => dispatch(actions.recordMessageHandler()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ScanDetail);