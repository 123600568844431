import * as actionTypes from "../actionTypes";

const initialState = {
    loading: false,
    uploadLoading: false,
    uploadImageLoading: false,
    manifestList: null,
    recordList: null,
    scanData:null,
    scanList: null,
    scanGraphData:null,
    scanPieGraphData:null,
    error: null,
    success: null,
    uploadError: null,
    uploadSuccess: null,
    uploadImageError: null,
    uploadImageSuccess: null,
    uploadRecordImageLoading: false,
    uploadRecordImageError: null,
    uploadRecordImageSuccess: null,
    uploadMessage:null,
    uploadImageMessage:null,
    maifestSelectData:null,
    countryData:null,
    successPass:null,
    downloadFileLoading: null,
    downloadFileError: null,
    downloadFileSuccess: null
};

export const recordReducer = (state = initialState, action) => {
    switch (action.type) {
        // Upload Manifest
        case actionTypes.UPLOAD_MANIFEST_START:
            return { ...state, uploadLoading: true, uploadError: null, uploadSuccess: null,uploadMessage:null };
        case actionTypes.UPLOAD_MANIFEST_SUCCESS:
            return { ...state, uploadLoading: false, uploadError: null, uploadSuccess: action.payload,uploadMessage:action.payload.message };
        case actionTypes.UPLOAD_MANIFEST_FAILURE:
            return { ...state, uploadLoading: false, uploadError: action.payload, uploadSuccess: null,uploadMessage:null };
        
        case actionTypes.MANAGE_RECORD_START:
            return { ...state, loading: true, error: null, success: null,message:null };
        case actionTypes.MANAGE_RECORD_SUCCESS:
            return { ...state, loading: false, error: null, success:true,message:action.payload.message };
        case actionTypes.MANAGE_RECORD_FAILURE:
            return { ...state, loading: false, error: action.payload, success: null,message:null };
            
        case actionTypes.GET_MANIFEST_LIST_START:
            return { ...state, loading: true, error: null, success: null };
        case actionTypes.GET_MANIFEST_LIST_SUCCESS:
            return { ...state, loading: false, error: null, success: null, manifestList: action.payload };
        case actionTypes.GET_MANIFEST_LIST_FAILURE:
            return { ...state, loading: false, error: action.payload, success: null };
        
        case actionTypes.GET_RECORDS_LIST_START:
            return { ...state, loading: true, error: null, success: null };
        case actionTypes.GET_RECORDS_LIST_SUCCESS:
            return { ...state, loading: false, error: null, success: null, recordList: action.payload };
        case actionTypes.GET_RECORDS_LIST_FAILURE:
            return { ...state, loading: false, error: action.payload, success: null };
        
        case actionTypes.SCAN_DATA_START:
            return { ...state, loading: true, error: null, success: null };
        case actionTypes.SCAN_DATA_SUCCESS:
            return { ...state, loading: false, error: null, success: true, scanData: action.payload.recordData };
        case actionTypes.SCAN_DATA_FAILURE:
            return { ...state, loading: false, error: action.payload, success: null };

        case actionTypes.GET_SCAN_LIST_START:
            return { ...state, loading: true, error: null, success: null };
        case actionTypes.GET_SCAN_LIST_SUCCESS:
            return { ...state, loading: false, error: null, success: null, scanList: action.payload };
        case actionTypes.GET_SCAN_LIST_FAILURE:
            return { ...state, loading: false, error: action.payload, success: null };

        case actionTypes.GET_SCAN_GRAPH_DATA_START:
            return { ...state, loading: true, error: null, success: null };
        case actionTypes.GET_SCAN_GRAPH_DATA_SUCCESS:
            return { ...state, loading: false, error: null, success: null, scanGraphData: action.payload };
        case actionTypes.GET_SCAN_GRAPH_DATA_FAILURE:
            return { ...state, loading: false, error: action.payload, success: null };

        case actionTypes.GET_MANIFEST_DATA_START:
            return { ...state, loading: true, error: null, success: null };
        case actionTypes.GET_MANIFEST_DATA_SUCCESS:
            return { ...state, loading: false, error: null, success: null, maifestSelectData: action.payload.data[0],countryData:action.payload.data[1] };
        case actionTypes.GET_MANIFEST_DATA_FAILURE:
            return { ...state, loading: false, error: action.payload, success: null };

        case actionTypes.CHANGE_PASS_START:
            return { ...state, loading: true, error: null, successPass: null };
        case actionTypes.CHANGE_PASS_SUCCESS:
            return { ...state, loading: false, error: null, successPass: true};
        case actionTypes.CHANGE_PASS_FAILURE:
            return { ...state, loading: false, error: action.payload, successPass: null };

        case actionTypes.GET_SCAN_PIE_GRAPH_START:
            return { ...state, loading: true, error: null, success: null };
        case actionTypes.GET_SCAN_PIE_GRAPH_SUCCESS:
            return { ...state, loading: false, error: null, success: null, scanPieGraphData: action.payload };
        case actionTypes.GET_SCAN_PIE_GRAPH_FAILURE:
            return { ...state, loading: false, error: action.payload, success: null };

        case actionTypes.UPLOAD_IMAGES_START:
            return { ...state, uploadImageLoading: true, uploadImageError: null, uploadImageSuccess: null,uploadImageMessage:null};
        case actionTypes.UPLOAD_IMAGES_SUCCESS:
            return { ...state, uploadImageLoading: false, uploadImageError: null, uploadImageSuccess: action.payload,uploadImageMessage:action.payload.message };
        case actionTypes.UPLOAD_IMAGES_FAILURE:
            return { ...state, uploadImageLoading: false, uploadImageError: action.payload, uploadImageSuccess: null,uploadImageMessage: null };

        case actionTypes.DOWNLOAD_FILE_START:
            return { ...state, downloadFileLoading: true, downloadFileError: null, downloadFileSuccess: null};
        case actionTypes.DOWNLOAD_FILE_SUCCESS:
            return { ...state, downloadFileLoading: false, downloadFileError: null, downloadFileSuccess: action.payload };
        case actionTypes.DOWNLOAD_FILE_FAILURE:
            return { ...state, downloadFileLoading: false, downloadFileError: action.payload, downloadFileSuccess: null };

        case actionTypes.UPLOAD_RECORD_IMAGE_START:
            return { ...state, uploadRecordImageLoading: true, uploadRecordImageError: null, uploadRecordImageSuccess: null };
        case actionTypes.UPLOAD_RECORD_IMAGE_SUCCESS:
            return { ...state, uploadRecordImageLoading: false, uploadRecordImageError: null, uploadRecordImageSuccess: action.payload };
        case actionTypes.UPLOAD_RECORD_IMAGE_FAILURE:
            return { ...state, uploadRecordImageLoading: false, uploadRecordImageError: action.payload, uploadRecordImageSuccess: null };

        case actionTypes.RECORD_MESSAGE_HANDLER:
            return { ...state, loading: false,successPass:null,uploadMessage:null, uploadImageMessage:null,scanData:null,uploadLoading: false, uploadImageLoading: false, error: action.payload, success: action.payload, uploadError: action.payload, uploadSuccess: action.payload, uploadImageLoading: action.payload, uploadImageSuccess: action.payload, uploadImageError: action.payload, scanData:null, uploadRecordImageLoading: false, uploadRecordImageSuccess: action.payload, uploadRecordImageError: action.payload, downloadFileLoading: false, downloadFileSuccess: action.payload, downloadFileError: action.payload };
        default:
            return { ...state };
    }
};
