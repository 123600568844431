import HttpService from "./http.service";

class RecordService extends HttpService {
    upload = (params) => this.post("record/manage-manifest", params);

    listManifest = (params) => this.get("record/list-manifest", params);

    listRecord = (params) => this.get("record/list-records", params);

    scan = (params) => this.post("record/scan-document", params);

    uploadImage = (params) => this.post("record/upload-images", params);

    uploadRecordImage = (params) => this.post("record/upload-record-image", params);

    listScan = (params) => this.get("record/list-scan", params);

    scanGraphData = (params) => this.get("record/scan-graph-data", params);

    scanPieGraphData = (params) => this.get("record/scan-pie-data", params);

    updateRecord = (params) => this.post("record/update-record", params);

    manifestData  = (params) => this.get("record/select-manifest", params);

    changePass  = (params) => this.post("auth/change-password", params);

    downloadFile = (params) => this.get("record/file-download", params)

}

export default new RecordService();
