import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import MainLayoutRoutes from "./mainRoutes";
import SignIn from "../container/auth/signIn/signIn";
import ScanDetail from "../container/scanDetail/index"
import GermanDetail from "../container/scanDetail/germanDetail"
import { Confirmation, HelpModal } from "../components";
// import './animal'
import Dashboard from "../container/homePage";

const Users = React.lazy(() => import('../container/users'));
const CreateUser = React.lazy(() => import('../container/users/createUser/createUser'));
const UploadManifest = React.lazy(() => import('../container/uploadManifest'));
const Manifest = React.lazy(() => import('../container/manifest'));
const Record = React.lazy(() => import('../container/manifest/record/record'));
// const ScanDetail = React.lazy(() => import('../container/scanDetail'));
const ScanListing = React.lazy(() => import('../container/scanListing'));
const UpdateRecord = React.lazy(() => import('../container/manifest/record/updateRecord'));
const UpdateManifest = React.lazy(() => import('../container/manifest/updateManifest'));
const UploadManifestNew = React.lazy(() => import('../container/uploadManifestNew'));

function Routes() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={SignIn} />
        <Route path="/login" exact component={SignIn} />
        <MainLayoutRoutes exact path="/dashboard" render={matchprops => (<Dashboard {...matchprops} />)}
          routeName={'dashboard'} />
        {/* <MainLayoutRoutes exact path="/users" render={matchprops => (<Users {...matchprops} />)}/>
        <MainLayoutRoutes exact path="/users/:id" render={matchprops => (<CreateUser {...matchprops} />)}/>
        <MainLayoutRoutes exact path="/users/createNew" render={matchprops => (<CreateUser {...matchprops} />)}/> */}
        {/* <MainLayoutRoutes exact path="/upload-manifest" render={matchprops => (<UploadManifest {...matchprops} />)}/> */}
        <MainLayoutRoutes exact path="/manifest" render={matchprops => (<UploadManifestNew {...matchprops} />)} />
        {/* <MainLayoutRoutes exact path="/manifest" render={matchprops => (<Manifest {...matchprops} />)}/> */}
        <MainLayoutRoutes exact path="/manifest/record/:id" render={matchprops => (<Record {...matchprops} />)} />
        <MainLayoutRoutes exact path="/qr-scan/:record_id?" render={matchprops => (<ScanListing {...matchprops} />)} />
        <Route exact path="/manifest/scan/:id" component={ScanDetail} />
        <Route exact path="/manifest/scan/german/demo" component={GermanDetail} />
        <MainLayoutRoutes exact path="/manifest/editRecord" render={matchprops => (<UpdateRecord {...matchprops} />)} />
        <MainLayoutRoutes exact path="/manifest/editManifest" render={matchprops => (<UpdateManifest {...matchprops} />)} />
      </Switch>
      <Confirmation />
      <HelpModal />
    </Router>
  )
    ;
}

export default Routes;


// https://www.youtube.com/watch?v=Ll4JtXQ2ag0
// npm i react-otp-input
