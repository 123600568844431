import * as actions from "../actions";
import * as actionsTypes from "../actionTypes";
import {put, takeEvery, all, fork} from "redux-saga/effects";
import Service from '../../services/service.service'


function* getServicesList(action) {
  try {
    if (action.payload) {
      const page = action.payload?.pageNo;
      const pageSize = action.payload?.pageSize;
      const search = action.payload?.search;
      let params = {page, pageSize, search};
      const response = yield Service.servicesList(params);
      if (response?.data.statusCode === 200) {
        yield put(actions.getServicesListSuccess(response.data.data));
      } else {
        yield put(actions.getServicesListFailure("network error"));
      }
    }
  } catch (err) {
    console.log(err.response)
    let error = err?.response?.data?.message
    if (!error) {
      error = 'network error'
    }
    yield put(actions.getServicesListFailure(error));
  }
}
function* getServicesData(action) {
  try {
    if (action.payload) {
      let service_id = action.payload;
      const response = yield Service.servicesData({service_id});
      if (response?.data.statusCode === 200) {
        yield put(actions.getServicesDataSuccess(response.data.data));
      } else {
        yield put(actions.getServicesDataFailure("network error"));
      }
    }
  } catch (err) {
    console.log(err.response)
    let error = err?.response?.data?.message
    if (!error) {
      error = 'network error'
    }
    yield put(actions.getServicesDataFailure(error));
  }
}


function* getServicesListSaga() {
  yield takeEvery(actionsTypes.GET_SERVICES_LIST_START, getServicesList);
}
function* getServicesDataSaga() {
  yield takeEvery(actionsTypes.GET_SERVICES_DATA_START, getServicesData);
}

export function* servicesSaga() {
  yield all([fork(getServicesListSaga),fork(getServicesDataSaga)]);
}
