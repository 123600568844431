import React, { useEffect, useState } from 'react';
import '../../assets/scss/germanDetail.scss';
import { Container } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { mainHeading, dumyImage,circleImage,mainHeadingGerman } from '../../assets/images/img/index';
import { useParams } from 'react-router-dom';



function CardRow({ leftLabel, leftValue, rightLabel, rightValue,index}) {
    console.log(leftValue);
    const isGreen = leftValue === 'approved';
    const isName = leftLabel == 'Name';
    return (
        <div className='german-card-div-row'>
            <span className= {` ${isName ? 'german-left-span fullww':'german-left-span'}`}>
                {leftLabel}:<br />
                <span className={`german-inner-text ${isGreen ? 'german-status-design' : ''}`}>
                    {leftValue}
                </span>
            </span>
            {rightLabel && (
                <span className='german-right-span'>
                    {rightLabel}:<br />
                    <span className='german-inner-text'>{rightValue}</span>
                </span>
            )}
        </div>
    );
}
function ScanDetailGerman(props) {
    const {scanInfo} = props
    const [scanData, setScanData] = useState(null);
    const { id } = useParams();
    useEffect(() => {
        document.body.classList.add('bod-bg-full');
        return () => {
          document.body.classList.remove('bod-bg-full');
        };
    },[]);

    const data = [
        // {
        //     leftLabel: 'Status',
        //     leftValue: '--',
        //     rightLabel: 'Date of Approval',
        //     rightValue: '--',
        // },
        // {
        //     leftLabel: 'Permit Type',
        //     leftValue: '--',
        // },
        {
            leftLabel: 'Name',
            leftValue: scanInfo?.name,
        },
        {
            leftLabel: 'Nationality',
            leftValue: scanInfo?.nationality,
            rightLabel: scanInfo?.pass_no && new Date(scanInfo?.pass_expire_date)>new Date(scanInfo?.manifest_date) ? 'Passport Number' : 'Status',
            rightValue: scanInfo?.pass_no && new Date(scanInfo?.pass_expire_date)>new Date(scanInfo?.manifest_date) ? scanInfo?.pass_no : "Laissez-passer"
        }
    ];
    
    const dataTwo = [
        {
            leftLabel: 'App Number',
            leftValue: scanInfo?.a_number,
            rightLabel: 'Gender',
            rightValue: scanInfo?.gender,
          
        },
        {
            leftLabel: 'Submission Date',
            leftValue: scanInfo?.manifest_date_formated
            // rightLabel: 'Permit Validity',
            // rightValue: '--',
        },
        {
            leftLabel: 'Date of Birth',
            leftValue: scanInfo?.dob,
            rightLabel: 'Passport EXPIRY',
            rightValue: scanInfo?.pass_expire_date,
        },
    ];

    useEffect(() => {
        if (scanInfo && scanInfo?.pass_no && new Date(scanInfo?.pass_expire_date)>new Date(scanInfo?.manifest_date)) {
            setScanData(dataTwo);
        } else {
            if (!scanInfo) {
                setScanData([]);
            } else {
                const filteredData = dataTwo.filter(item => item.rightLabel !== 'Passport EXPIRY');
                console.log('final data== ', filteredData);
                setScanData(filteredData);
            }
        }
        // console.log('final data== ', scanData);
    }, [id, scanInfo]);


    return (
        <div className='bod-bg-pdf'>
        <div className='bod-center-pdf'>
            <div className='logo-main'>
            <img src={mainHeadingGerman} />
            </div>
            <Container>
                <Card className='german-card-style' elevation={2}>
                    <div>
                        {/* <div className='german-align-adjust'>
                            <img src={mainHeading} />
                        </div> */}
                        <div className='german-main-image-div' >
                            <div className='german-outer-circle' >
                                <img className='german-inner-circle' src={scanInfo?.user_image?.completedUrl} />
                            </div>
                        </div>
                       
                        {data.map((item, index) => (
                            <>
                            {console.log(item,index)}
                                {(index == 1 || index == 3) && <span className='dots-both-pdf'></span>}
                                <CardRow
                                    key={index}
                                    leftLabel={item.leftLabel}
                                    
                                    leftValue={item.leftValue}
                                    rightLabel={item.rightLabel}
                                    rightValue={item.rightValue}
                                />
                            </>
                        ))}

                        <div className='pdf-blue-bgg'>
                            {scanData?.map((item, index) => (
                                <>
                                    {index == 2 && <span className='dots-both-pdf2'><span></span></span>}
                                    <CardRow
                                        key={index}    
                                        leftLabel={item.leftLabel}
                                        leftValue={item.leftValue}
                                        rightLabel={item.rightLabel}
                                        rightValue={item.rightValue}
                                    />
                                </>
                            ))}
                        </div>
                    </div>
                </Card>
            </Container>
        </div>
        </div>
    )
}

export default ScanDetailGerman;