import React, { useState } from "react";
import { Redirect, Route } from "react-router-dom";
// import internal(own) modules
import MainLayout from "./MainLayout";
import {
  C_OTC_STORAGE,

  sidebarTabsList,
} from "../environment";

// const adminRoutes = ['users', 'licensed-label-service', 'labels-licensed-territories', 'invoice-generation', 'labels-licensed-territories/create-service', 'invoice', 'labels/edit', 'contents/edit',]
// const cpManagerRoutes = ['dashboard', 'cps', 'references', 'labels', 'contents', 'labels-service', 'content-report', 'label-service-report', 'changePassword']
// const cpRoutes = ['dashboard', 'labels', 'contents', 'content-report', 'label-service-report', 'invoice', 'changePassword']
// const financeRoutes = ['dashboard', 'cps', 'labels', 'upload-reports', 'service-report', 'label-service-report', 'content-report', 'invoice', 'changePassword']
// const commercialTeamRoutes = ['dashboard', 'cps', 'references', 'labels', 'labels/edit', 'contents', 'content-report', 'labels-service', 'label-service-report', 'invoice-generation', 'invoice', 'contents/edit', 'upload-reports', 'labels-licensed-territories', 'labels-licensed-territories/licensed-service', 'service-report', 'changePassword']
// const mediaTeamRoutes = ['dashboard', 'labels', 'contents', 'labels-service', 'changePassword']

const MainLayoutRoute = ({ render, ...rest }) => {

  const [user] = useState(JSON.parse(localStorage.getItem(C_OTC_STORAGE)));
  // const role = user && user.role;
  // const sidebar = (role === '1') ? sidebarTabsList : ''

  return (
    <Route
      {...rest}
      className={'app-logo-text'}
      render={matchProps => {
        return user ? (
          <MainLayout sidebar={sidebarTabsList}>
            {render(matchProps)}
          </MainLayout>
        ) : (
          <Redirect to={'/'} />
        );
        // return (
        //   (
        //     (adminRoutes.includes(rest.path.split('/')[1]) && (role === '1')) ||
        //     (cpManagerRoutes.includes(rest.path.split('/')[1]) && (role === '1' || role === '2')) ||
        //     (cpRoutes.includes(rest.path.split('/')[1]) && (role === '1' || role === '3')) ||
        //     (financeRoutes.includes(rest.path.split('/')[1]) && (role === '1' || role === '4')) ||
        //     (commercialTeamRoutes.includes(rest.path.split('/')[1]) && (role === '5')) ||
        //     (mediaTeamRoutes.includes(rest.path.split('/')[1]) && (role === '1' || role === '6')))
        //     ?
        //     <MainLayout sidebar={sidebar}>{render(matchProps)}</MainLayout>
        //     : user ? <Redirect to={'/dashboard'} /> : <Redirect to={'/'} />
        //   // <MainLayout sidebar={sidebar}>{render(matchProps)}</MainLayout> /** Login when dashboard route was hidden.....now logic changes so no need to do this */
        //   // : user && role != 6 ? <Redirect to={'/dashboard'}/> 
        //   // : user && role == 6 ? <Redirect to={'/labels'}/>
        //   // : <Redirect to={'/'}/>
        // )

      }}
    />
  );
};

export default MainLayoutRoute;
