import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import './styles.scss'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
    },
}));

export default function Loader(props) {
    const classes = useStyles();

    return (
        <div className='loading-container'>
            <div className={classes.root}>
                <CircularProgress size={props?.size ? props?.size : 40} className={props.className ? props.className : ''}  />
            </div>
        </div>
    );
}
