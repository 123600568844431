import { combineReducers } from "redux";
import { authReducer } from "./auth";
import { dashboardReducer } from "./dashboard";
import { usersReducer } from "./usersReudcer";
import { confirmationReducer } from './confirmationReducer';
import { helpReducer } from './helpReducer';
import { recordReducer } from './recordReducer';

export const rootReducer = combineReducers({
  authReducer, dashboardReducer, usersReducer, confirmationReducer, helpReducer, recordReducer
});
