import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: 'relative'
    // background:'lightblue',
    // padding:10
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
    padding: 0,
    color: "#0D0D39",
  },
  tableContainer: {
    maxHeight: 460
  },
  userTableContainer: {
    maxHeight: 390
  },
  tableHead: {
    border: "none",
    borderTop: "1px solid transparent",
    borderBottom: "1px solid transparent",
    fontWeight: "600",
    // color: "#000c",
    color: "#242B42",
    textTransform: "capitalize",
    fontSize: 14,
    lineHeight: '14px',
    paddingTop:"20px",
    paddingBottom:"20px",

  },
  tableCell: {
    border: "none",
    opacity: 0.9,
    fontSize: '13px !important',
    color: "#242B42",
    textTransform: "capitalize",
    borderBottom: "1px solid rgba(3, 2, 41, 0.05) !important",
  },
  actionCell: {
    opacity: 1,
    display: 'flex',
    alignItems: 'center'
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  actionIcon: {
    fontSize: 20,
    // marginLeft:10,
    marginLeft: 10,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
