import * as actions from "../actions";
import * as actionsTypes from "../actionTypes";
import { put, takeEvery, all, fork } from "redux-saga/effects";
import RecordService from '../../services/record.service'


function* uploadManifest(action) {
    try {
        if (action.payload) {
            let params={}
            let response;
            console.log("action.payload",action.payload)
            if(action.payload.manifest_id){
                const _id=action.payload.manifest_id
                if(action.payload.photos_uploaded){
                    console.log("photo")
                    const photos_uploaded=action.payload.photos_uploaded
                    params={photos_uploaded,_id}
                    response = yield RecordService.upload(params);
                }
                else if(action.payload.is_processed ==0 || action.payload.is_processed){
                    console.log("processed")
                    const is_processed=action.payload.is_processed
                    params={is_processed,_id}
                    response = yield RecordService.upload(params);
                }
                else if(action.payload.status == 0 || action.payload.status){
                    console.log("status")
                    const status=action.payload.status
                    params={status,_id}
                    response = yield RecordService.upload(params);
                }
                else{
                    const data = new FormData();
                    data.append('_id', _id);
                    data.append('country', action.payload.country.map(item => item.value).join(', '));
                    data.append('title', action.payload.title);
                    data.append('qr_ttl', action.payload.qr_ttl);
                    data.append('is_qr', action.payload.is_qr);
                    data.append('permit_validity', action.payload.permit_validity);
                    data.append('submission_date', action.payload.submission_date);
                    data.append('format_type', action.payload.format_type);
                    response = yield RecordService.upload(data);
                }
            }
            else{
                const data = new FormData();
                data.append('country', action.payload.country.map(item => item.value).join(', '));
                data.append('title', action.payload.title);
                data.append('qr_ttl', action.payload.qr_ttl);
                data.append('is_qr', action.payload.is_qr);
                data.append('file', action.payload.file);
                data.append('permit_validity', action.payload.permit_validity);
                data.append('submission_date', action.payload.submission_date);
                data.append('format_type', action.payload.format_type);
                response = yield RecordService.upload(data);
            }
            
            // return;
            if (response?.status === 201) {
                yield put(actions.uploadManifestSuccess(response));
            } else {
                yield put(actions.uploadManifestFailure("network error"));
            }
        }
    } catch (err) {
        console.log("Error:  ", err);
        let error = err?.response?.data?.message
        if (!error) {
            error = 'network error'
        }
        yield put(actions.uploadManifestFailure(error));
    }
}
function* uploadManifestSaga() {
    yield takeEvery(actionsTypes.UPLOAD_MANIFEST_START, uploadManifest);
}

function* updateRecord(action) {
    try {
        if (action.payload) {
            let response;
            response = yield RecordService.updateRecord(action.payload);  
            // return;
            if (response?.data?.statusCode === 200) {
                yield put(actions.manageRecordSuccess(response?.data));
            } else {
                yield put(actions.manageRecordFailure("network error"));
            }
        }
    } catch (err) {
        console.log("Error:  ", err);
        let error = err?.response?.data?.message
        if (!error) {
            error = 'network error'
        }
        yield put(actions.manageRecordFailure(error));
    }
}
function* updateRecordSaga() {
    yield takeEvery(actionsTypes.MANAGE_RECORD_START, updateRecord);
}

function* getManifestList(action) {
    try {
        if (action.payload) {
            const page = action.payload?.pageNo;
            const perPage = action.payload?.perPage;
            const status = '';
            const photos_uploaded = action?.payload?.photos_uploaded;
            let params = { page, perPage, status, photos_uploaded };
            const response = yield RecordService.listManifest(params);
            response?.data.data.map(item => {
                var countries = item.country.split(',');
                item.country = countries.map(country => {
                    return { value: country, text: country }
                });
            });
            if (response?.status === 200) {
                yield put(actions.getManifestListSuccess(response));
            } else {
                yield put(actions.getManifestListFailure("network error"));
            }
        }
    } catch (err) {
        console.log("Error ", err);
        let error = err?.response?.data?.message
        if (!error) {
            error = 'network error'
        }
        yield put(actions.getManifestListFailure(error));
    }
}
function* getManifestListSaga() {
    yield takeEvery(actionsTypes.GET_MANIFEST_LIST_START, getManifestList);
}

function* getRecordsList(action) {
    try {
        if (action.payload) {
            const page = action.payload?.pageNo;
            const perPage = action.payload?.perPage;
            const status = '';
            const manifest_id = action.payload?.id;
            let params = { page, perPage, status, manifest_id };

            const response = yield RecordService.listRecord(params);
            console.log("resp====  ", response);
            if (response?.status === 200) {
                yield put(actions.getRecordsListSuccess(response));
            } else {
                yield put(actions.getRecordsListFailure("network error"));
            }
        }
    } catch (err) {
        console.log("Error ", err);
        let error = err?.response?.data?.message
        if (!error) {
            error = 'network error'
        }
        yield put(actions.getRecordsListFailure(error));
    }
}
function* getRecordsListSaga() {
    yield takeEvery(actionsTypes.GET_RECORDS_LIST_START, getRecordsList);
}

function* uploadImages(action) {
    try {
        if (action.payload) {
            console.log('action ', action.payload.image.length);
            const data = new FormData();
            const images = action.payload.image
            data.append('manifest_id', action.payload.manifest_id);
            data.append('file', images);
            // data.append('image', action.payload.image);
            const response = yield RecordService.uploadImage(data);
            console.log('record manifest saga === ', response);
            
            if (response?.status === 201) {
                yield put(actions.uploadImagesSuccess(response));
            } else {
                yield put(actions.uploadImagesFailure("network error"));
            }
        }
    } catch (err) {
        console.log("Error:  ", err);
        let error = err?.response?.data?.message
        if (!error) {
            error = 'network error'
        }
        yield put(actions.uploadImagesFailure(error));
    }
}
function* uploadImageSaga() {
    yield takeEvery(actionsTypes.UPLOAD_IMAGES_START, uploadImages);
}

function* uploadRecordImage(action) {
    try {
        if (action.payload) {
            const data = new FormData();
            data.append('manifest_id', action.payload.manifest_id);
            data.append('record_id', action.payload.record_id);
            data.append('user_image', action.payload.user_image);
            const response = yield RecordService.uploadRecordImage(data);
            console.log('record manifest saga === ', response);
            
            if (response?.status === 201) {
                yield put(actions.uploadRecordImageSuccess(response));
            } else {
                yield put(actions.uploadRecordImageFailure("network error"));
            }
        }
    } catch (err) {
        console.log("Error:  ", err);
        let error = err?.response?.data?.message
        if (!error) {
            error = 'network error'
        }
        yield put(actions.uploadRecordImageFailure(error));
    }
}
function* uploadRecordImageSaga() {
    yield takeEvery(actionsTypes.UPLOAD_RECORD_IMAGE_START, uploadRecordImage);
}

function* scanData(action) {
    try {
        if (action.payload) {
            const browser = action.payload.browser;
            const device_type = action.payload.device_type;
            const lat = action.payload.lat;
            const long = action.payload.long;
            const os_type = action.payload.os_type;
            const country = action.payload.country;
            const city = action.payload.city;
            const user_agent = action.payload.user_agent;
            const record_id = action.payload.record_id;

            const params ={browser,device_type,lat,long,os_type,country,city,user_agent,record_id}
            const response = yield RecordService.scan(params);
            console.log('record manifest saga === ', response?.data);
            // return;
            if (response?.status === 201) {
                console.log("response=============",response)
                yield put(actions.scanDataSuccess(response?.data));
            } else {
                yield put(actions.scanDataFailure("network error"));
            }
        }
    } catch (err) {
        console.log("Error:  ", err);
        let error = err?.response?.data?.message
        if (!error) {
            error = 'network error'
        }
        yield put(actions.scanDataFailure(error));
    }
}
function* scanDataSaga() {
    yield takeEvery(actionsTypes.SCAN_DATA_START, scanData);
}

function* getScanList(action) {
    try {
        if (action.payload) {
            const page = action.payload?.pageNo;
            const perPage = action.payload?.perPage;
            const status = '';
            const record_id = action.payload?.record_id;
            const manifest_id = action.payload?.manifest_id;
            const country = action.payload?.country;
            let params = { page, perPage, status, record_id,manifest_id,country};

            const response = yield RecordService.listScan(params);
            console.log("resp====  ", response);
            if (response?.status === 200) {
                yield put(actions.getScanListSuccess(response));
            } else {
                yield put(actions.getScanListFailure("network error"));
            }
        }
    } catch (err) {
        console.log("Error ", err);
        let error = err?.response?.data?.message
        if (!error) {
            error = 'network error'
        }
        yield put(actions.getScanListFailure(error));
    }
}
function* getScanListSaga() {
    yield takeEvery(actionsTypes.GET_SCAN_LIST_START, getScanList);
}

function* getScanGraphData(action) {
    try {
        const year = action?.payload?.year || '';
        const month = action?.payload?.month || '';
        const uniqueVisitors = action?.payload?.uniqueVisitors;
        const country = action?.payload?.country;
        const manifest = action?.payload?.manifest;
        let params = { year, month, uniqueVisitors,country,manifest};

        const response = yield RecordService.scanGraphData(params);
        console.log("resp====  ", response);
        if (response?.status === 200) {
            yield put(actions.getScanGraphDataSuccess(response));
        } else {
            yield put(actions.getScanGraphDataFailure("network error"));
        }
    } catch (err) {
        console.log("Error ", err);
        let error = err?.response?.data?.message
        if (!error) {
            error = 'network error'
        }
        yield put(actions.getScanGraphDataFailure(error));
    }
}
function* getScanGraphDataSaga() {
    yield takeEvery(actionsTypes.GET_SCAN_GRAPH_DATA_START, getScanGraphData);
}


function* getScanPieGraph(action) {
    try {
        const response = yield RecordService.scanPieGraphData();
        console.log("resp====  ", response);
        if (response?.status === 200) {
            yield put(actions.getScanPieGraphSuccess(response));
        } else {
            yield put(actions.getScanPieGraphFailure("network error"));
        }
    } catch (err) {
        console.log("Error ", err);
        let error = err?.response?.data?.message
        if (!error) {
            error = 'network error'
        }
        yield put(actions.getScanPieGraphFailure(error));
    }
}
function* getScanPieGraphSaga() {
    yield takeEvery(actionsTypes.GET_SCAN_PIE_GRAPH_START, getScanPieGraph);
}

function* getManifestData(action) {
    try {
        const response = yield RecordService.manifestData();
        console.log("response?.data",response?.data)
        if (response?.data.statusCode === 200) {
            yield put(actions.getManifestDataSuccess(response.data));
        } else {
            yield put(actions.getManifestDataFailure("network error"));
        }
    } catch (err) {
        console.log("Error ", err);
        let error = err?.response?.data?.message
        if (!error) {
            error = 'network error'
        }
        yield put(actions.getManifestDataFailure(error));
    }
}
function* getManifestDataSaga() {
    yield takeEvery(actionsTypes.GET_MANIFEST_DATA_START, getManifestData);
}

function* changePass(action) {
    try {
        if (action.payload) {
            const newPassword = action.payload.newPassword;
            const params ={newPassword}
            const response = yield RecordService.changePass(params);
            if (response?.data.statusCode === 200) {
                yield put(actions.changePassSuccess(response.data));
            } else {
                yield put(actions.changePassFailure("network error"));
            }
        }
    } catch (err) {
        console.log("Error ", err);
        let error = err?.response?.data?.message
        if (!error) {
            error = 'network error'
        }
        yield put(actions.changePassFailure(error));
    }
}
function* changePassSaga() {
    yield takeEvery(actionsTypes.CHANGE_PASS_START, changePass);
}

function* downloadFile(action) {
    try {
        if (action.payload) {
            let params = { type: action.payload.type, manifest_id: action.payload.manifestId, filename: action.payload.filename };

            const response = yield RecordService.downloadFile(params);
                        
            if (response?.data.statusCode === 200) {
                const downloadLink = document.createElement('a');
                if(action.payload.type == 1)
                    var linkSource = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';
                else if(action.payload.type == 2)
                    var linkSource = 'data:application/pdf;base64,';
                downloadLink.href = linkSource+response.data.data;
                downloadLink.download = action.payload.filename;
                downloadLink.click();
                yield put(actions.downloadFileSuccess(response));
            } else {
                yield put(actions.downloadFileFailure("network error"));
            }
        }
    } catch (err) {
        console.log("Error:  ", err);
        let error = err?.response?.data?.message
        if (!error) {
            error = 'network error'
        }
        yield put(actions.downloadFileFailure(error));
    }
}
function* downloadFileSaga() {
    yield takeEvery(actionsTypes.DOWNLOAD_FILE_START, downloadFile);
}

export function* recordSaga() {
    yield all([
        fork(uploadManifestSaga),
        fork(getManifestListSaga),
        fork(getRecordsListSaga),
        fork(uploadImageSaga),
        fork(uploadRecordImageSaga),
        fork(scanDataSaga),
        fork(getScanListSaga),
        fork(getScanGraphDataSaga),
        fork(updateRecordSaga),
        fork(getScanPieGraphSaga),
        fork(getManifestDataSaga),
        fork(changePassSaga),
        fork(downloadFileSaga)
        
        
    ]);
}
