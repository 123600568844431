import HttpService from "./http.service";

class AuthService extends HttpService {
    login = (data) => this.post("auth/login", data);

    signup = (data) => this.post(`auth/register`, data);

    editUser = (data) => this.post(`updateProfile`, data);

    changePass = (data) => this.post(`auth/updatePassword`, data);

    forgotPassword = (data) => this.post(`auth/forget-password`,data);

    Verification = (data) => this.post(`auth/change-password`,data);

    logout = (data) => this.get("logout");
}

export default new AuthService();
