import React, { useState, useEffect, useRef } from 'react';
import * as actions from "../../store/actions";
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { Container, Modal } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import { InputRow, InputError } from "../index";
import { onChangeInput, validateInputs,validatePassword,validateConfirmPassword} from '../../services/utils'
import { useDispatch } from "react-redux";
import './manageManifest.scss';
import { Alert} from '../../components'
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  modal: {
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
    border: 'none'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    // boxShadow: theme.shadows[5],
    padding: theme.spacing(5, 10, 5, 10),
    display: 'flex',
    flexDirection: 'column',
    border: 'none',
    outline: 'none',
    borderRadius: '20px'
  },
}));

function Index(props) {
  const { recordReducer } = props
  const { uploadLoading,successPass, uploadSuccess } = recordReducer

  const initialState = {
    values: {
      newPassword: '',
      confirmNewPassword: '',
    },
    errors: {
      newPassword: '',
      confirmNewPassword: '',
    }
  }

  const [state, setState] = useState(initialState);
  const { values, errors } = state;

  const classes = useStyles();

  const dispatch = useDispatch();

  let {
    open,
    setOpen
  } = props;

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
   if(successPass){
      setOpen(false);
      setTimeout(() => {
        props.recordMessageHandler()
      }, 1000);
   }
  }, [successPass]);

  const updatePassword = () => {
    let { newPassword,confirmNewPassword} = values;
    const data = { newPassword, confirmNewPassword}
    let recordedErrors = validateInputs(data);
    let passwordErrors = validateConfirmPassword(values.newPassword, values.confirmNewPassword);

    if (Object.keys(recordedErrors).length > 0) {
      console.log(recordedErrors)
      setState((prevState) => ({
        ...prevState,
        errors: recordedErrors,
      }));
    } 
    else if (Object.keys(recordedErrors).length == 0 && !passwordErrors) {
      setState((prevState) => ({
        ...prevState,
        errors: {confirmNewPassword:"Confirm Password is not matched"},
      }));
  }
  else{
        const data = {newPassword}
        props.changePasswordStart(data);
    }
  }

  return (
    <div>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        // closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}

      >
        <Fade in={open} style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
          <Container component="main" maxWidth={'md'} style={{ padding: 0, maxWidth: '550px'}}>
            <div className='form-modal-close' onClick={handleClose}>
                <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5 30C23.5081 30 30 23.5081 30 15.5C30 7.49187 23.5081 1 15.5 1C7.49187 1 1 7.49187 1 15.5C1 23.5081 7.49187 30 15.5 30Z" fill="white" fill-opacity="0.9" stroke="#DDDDDD"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.69 11.3277C11.5425 11.3277 11.4097 11.4171 11.3542 11.5538C11.2987 11.6905 11.3316 11.8471 11.4373 11.95L14.9872 15.5L11.4373 19.0499C11.3428 19.1409 11.3048 19.2758 11.3379 19.4027C11.3711 19.5296 11.4702 19.6287 11.5971 19.6619C11.724 19.695 11.8589 19.657 11.9499 19.5626L15.4999 16.0126L19.0498 19.5626C19.1408 19.657 19.2757 19.695 19.4026 19.6619C19.5295 19.6287 19.6286 19.5296 19.6618 19.4027C19.6949 19.2758 19.6569 19.1409 19.5624 19.0499L16.0125 15.5L19.5624 11.95C19.6696 11.8458 19.7019 11.6865 19.6436 11.5488C19.5853 11.4111 19.4485 11.3233 19.299 11.3277C19.2049 11.3305 19.1155 11.3698 19.0498 11.4374L15.4999 14.9874L11.9499 11.4374C11.8817 11.3673 11.7879 11.3277 11.6901 11.3277H11.69Z" fill="#0C0D34"/>
                </svg>
            </div>
            <div className='manage-manifest-paper'>
              <div className='display-flex align-items-center justify-content-center'>
                <h2 className='modal-heading'>Update Password</h2>
              </div>
              <div>
                <div className='form-wrapper'>
                  <InputRow
                    types={[
                      { type: 'password', id: 'newPassword', value: values.newPassword, error: errors.newPassword, label: 'New Password', },
                      { type: 'password', id: 'confirmNewPassword', value: values.confirmNewPassword, error: errors.confirmNewPassword, label: 'Confirm New Password', },
                    ]}
                    onChangeInput={(value, key) => onChangeInput(value, key, state, setState)}
                    numOfInputPerRow={1}
                  />
                </div>
                <button onClick={updatePassword} className={`proceed-btn ${uploadLoading ? 'btn-disabled' : ''} mt-20`} disabled={uploadLoading}>
                  <span className='display-flex align-items-center justify-content-center'>
                    Update 
                    {uploadLoading && <CircularProgress color='white' style={{width: 15, height: 15, marginLeft: 10}}/>}
                  </span>
                </button>
              </div>
            </div>          
          </Container>
        </Fade>
      </Modal>
      {(successPass) &&
          <Alert type={successPass ? 'success' : 'error'} message={successPass ? "Password Updated Successfully" : 'Something went wrong!'} />}
    </div>
  );
}

const mapStateToProps = ({ recordReducer }) => {
  return { recordReducer };
};
const mapDispatchToProps = (dispatch) => {
  return {
    changePasswordStart: (data) => dispatch(actions.changePassStart(data)),
    recordMessageHandler: () => dispatch(actions.recordMessageHandler()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);

// function* gen1(){console.log(yield 1);console.log(yield 2);console.log(yield 3)} const iterator= gen1(); console.log(iterator.next('a').value);console.log(iterator.next('b').value);console.log(iterator.next('c').value)
