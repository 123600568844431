import * as actions from "../actions";
import * as actionsTypes from "../actionTypes";
import { all, fork, put, takeEvery } from "redux-saga/effects";
import AuthService from "../../services/auth.service";
import HttpService from "../../services/http.service";

function* loginStart(action) {
  try {
    const response = yield AuthService.login({
      email: action?.payload?.email,
      password: action?.payload?.password,
    });
    console.log('login response ===== ', response)
    if (response?.data?.accessToken) {
      HttpService.setToken(response.data.accessToken);//set token in header
      let user = response?.data?.user || {}; 
      HttpService.setUser(user._id);
      localStorage.setItem('user', JSON.stringify(user)); 
      yield put(actions.loginSuccess(response.data));
    } else {
      yield put(actions.loginFailure("Invalid Username/Password"));
    }
  } catch (err) {
    console.log('err ', err)
    let error = err?.response?.data?.message;

    if (!error) {
      error = err?.response?.message || "Network error";
    }
    yield put(actions.loginFailure(error));
  }
}

function* loginStartSaga() {
  yield takeEvery(actionsTypes.LOGIN_START, loginStart);
}

function* signUpStart(action) {
  try {
    let response = ''
    let payload = action.payload;
    let create = payload.create;
    delete payload.create
    if (create) {
      // create user flow
      delete payload.id
      response = yield AuthService.signup(payload);
    } else {
      // edit user flow
      response = yield AuthService.editUser(payload);
    }
    // const data = {
    //   email: action.payload.email,
    //   password: action.payload.password,
    //   name: action.payload.name,
    //   companyName: action.payload.companyName,
    //   role: action.payload.role,
    //   address: action.payload.address,
    //   mobile: action.payload.mobile,
    //   status: action.payload.status,
    // }


    if (response) {
      if (action.payload?.signup) {
        HttpService.setToken(response.data.data.token);
      }
      yield put(actions.signupSuccess(response?.data, action.payload?.signup));
    } else {
      yield put(actions.signupFailure('network error'));
    }
  } catch (err) {
    // console.log('er ', err)
    let error = err?.response?.data?.message;
    if (!error) {
      error = "network error"
    }
    yield put(actions.signupFailure(error));
  }
}

function* changePasswordStart(action) {
  try {
    const response = yield AuthService.changePass(action.payload);
    if (response?.data.statusCode == 200) {
      yield put(actions.changePasswordSuccess(response?.data.message));
    } else {
      yield put(actions.changePasswordFailure('Incorrect old password'));
    }
  } catch (err) {
    console.log('er ', err)
    let error = err?.response?.data?.message;
    if (!error) {
      error = "network error"
    }
    yield put(actions.changePasswordFailure(error));
  }
}

function* forgotPasswordStart(action) {
  try {
    const response = yield AuthService.forgotPassword(action.payload);
    if (response?.data.statusCode == 200) {
      yield put(actions.forgotPasswordSuccess(response?.data.message));
    } else {
      yield put(actions.forgotPasswordFailure('network error'));
    }
  } catch (err) {
    console.log('er ', err)
    let error = err?.response?.data?.message;
    if (!error) {
      error = "network error"
    }
    yield put(actions.forgotPasswordFailure(error));
  }
}

function* verifyPasswordStart(action) {
  try {
    const response = yield AuthService.Verification(action.payload);
    if (response?.data.statusCode == 200) {
      yield put(actions.verifyPasswordSuccess(response?.data.message));
    } else {
      yield put(actions.verifyPasswordFailure('incorrect verification key'));
    }
  } catch (err) {
    console.log('er ', err)
    let error = err?.response?.data?.message;
    if (!error) {
      error = "network error"
    }
    yield put(actions.verifyPasswordFailure(error));
  }
}


function* signupStartSaga() {
  yield takeEvery(actionsTypes.SIGNUP_START, signUpStart);
}
function* changePasswordStartSaga() {
  yield takeEvery(actionsTypes.CHANGE_PASSWORD_START, changePasswordStart);
}
function* forgotPasswordStartSaga() {
  yield takeEvery(actionsTypes.FORGOT_PASSWORD_START, forgotPasswordStart);
}
function* verifyPasswordStartSaga() {
  yield takeEvery(actionsTypes.VERIFY_PASSWORD_START, verifyPasswordStart);
}

export function* authSaga() {
  yield all([fork(loginStartSaga), fork(signupStartSaga), fork(changePasswordStartSaga), fork(forgotPasswordStartSaga), fork(verifyPasswordStartSaga)]);
}
