import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        outline: "none",
        maxWidth: '400px',
        maxHeight: '400px',
        margin: '0px auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));