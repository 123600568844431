import React, { useEffect, useState } from 'react';
import { Chart } from 'react-google-charts'
import { useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';



export const dataLineGraph = [
  ["x", "09/25/23", "09/26/23"],
  ['09/25/23', 0, 0],
  ['09/26/23', 10, 5],
  ['09/27/23', 23, 15],
  ['09/28/23', 17, 9],
  ['09/29/23', 18, 10],
  ['09/30/23', 9, 5],
  ['09/31/23', 11, 3],
  ['10/01/23', 27, 19],
];

// export const options = {
//   hAxis: {
//     title: "Date",
//   },
//   vAxis: {
//     title: "Scans",
//   },
//   series: {
//     1: { curveType: "function" },
//   },
// };

export const options = {
  chart: {
    title: "Scan Data Graph",
    // subtitle: "for current month",
  },
  annotations: {
    alwaysOutside: true,
    textStyle: {
      fontSize: 12,
      color: '#555',
    },
  }
};

export const dataPieChart = [
  ["United States", "Popularity"],
  ["Pakistan", 33],
  ["Germany", 26],
  ["Spain", 22],
];

export const dataPieHole = [
  ["United States", "Popularity"],
  ["New York", 50],
  ["Islamabad", 26],
  ["Afghanistan", 22],
  ["Washington", 10],
];

export const optionsPieChart = {
  sliceVisibilityThreshold: 0, // 20%
};

export const optionsPieHole = {
  pieHole: 0.5,
  is3D: false,
  sliceVisibilityThreshold: 0
};

function Index(props) {
  const { loading,data,dataDonut,dataPie,type } = props;
  const history = useHistory();
  const chartEvents = [{
    eventName: 'select',
    callback({ chartWrapper }) {
        const selectedItems = chartWrapper.getChart().getSelection();

        if (selectedItems.length > 0) {
          const selectedItem = selectedItems[0];
          const row = selectedItem.row; 
    
          const countryName = chartWrapper.getDataTable().getValue(row, 0);
          history.push('/qr-scan', { countryName });          
        }
    }
  }];
  return (
    <div>
      {type===1? 
        (
          loading ? <div className='display-flex justify-content-center align-items-center' style={{ width:"100%", height:"400px", color: "#5478FA" }}>
            <div className='display-flex align-items-center'>
              Loading Chart...
              <CircularProgress className='ml-10' color='primary' style={{width: 15, height: 15, marginLeft: 10}}/>
            </div>
          </div> :
          <Chart
            chartType="Line"
            loader={
              <div className='display-flex justify-content-center align-items-center' style={{ width:"100%", height:"400px", color: "#5478FA" }}>
                <div className='display-flex align-items-center'>
                  Loading Chart...
                  <CircularProgress className='ml-10' color='primary' style={{width: 15, height: 15, marginLeft: 10}}/>
                </div>
              </div>
            }
            width="100%"
            height="400px"
            data={data}
            options={options}
          />
        ):(type==2)?
        <Chart
          chartType="PieChart"
          loader={<div style={{ color: "#5478FA" }}>Loading Chart...</div>}
          width="100%"
          height="400px"
          data={dataPie}
          options={optionsPieChart}
          chartEvents={chartEvents}
        />:
        <Chart
          chartType="PieChart"
          loader={<div style={{ color: "#5478FA" }}>Loading Chart...</div>}
          width="100%"
          height="400px"
          data={dataDonut}
          options={optionsPieHole}
        />
      }
     
    </div>
  );
}

export default Index;

