import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import {Backdrop, Container, Grid} from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import {InputRow, PrimaryButton} from "../index";
import {onChangeInput} from "../../services/utils";
import {addTerritory} from "../../store/actions";
// import {'regionOptions'} from "../../environment";
import Loader from "../loading";

const useStyles = makeStyles((theme) => ({
  modal: {
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
    border:'none'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    // boxShadow: theme.shadows[5],
    padding: theme.spacing(5, 10, 5, 10),
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column'
  },
}));

export default function AddTerritoryServiceModal(props) {
  const classes = useStyles();
  // const [open, setOpen] = React.useState(false);
  // const {open, setOpen, state: {values, errors}, setState} = props
  const {open, setOpen, loading, state, setState, newTerritory, storeHandler, addNewError,error=''} = props;
  const {values, errors} = state;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {

  }, [props.addNewError])
  return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}

        open={open}
        onClose={handleClose}
        // closeAfterTransition
        // BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open} style={{border:'none'}}>
          <Container component="main" maxWidth="sm" style={{ padding:0,}}>
            <div className={classes.paper}>
              <div className='display-flex align-items-center justify-content-between'>
                <h1>{newTerritory ? 'Add Territory' : 'Add Service'}</h1>
                {error && <span className="error-class">{error}</span>}
              </div>
              <InputRow
                types={[
                  ...newTerritory ?
                    [
                      {
                        type: 'select',
                        value: values.region,
                        error: errors.region,
                        label: 'region',
                        dropdownList: 'regionOptions',
                      },
                      {value: values.title, error: errors.title, label: 'title'},
                    ]
                    :
                    [{value: values.service, error: errors.service, label: 'service'}],
                ]}
                onChangeInput={(value, key) => onChangeInput(value, key, state, setState)}
                numOfInputPerRow={1}
              />

              <div className='mt-20 ml-20 align-self-start '>
                {(loading) ? <Loader/> :
                  <div className='ml-10'><PrimaryButton onClick={storeHandler} value='submit'/></div>
                }
              </div>
            </div>
          </Container>
        </Fade>
      </Modal>
  );
}
