import React, { useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import * as actions from '../../../store/actions'
import { connect } from "react-redux";
import { onChangeInput, validateEmail, validateInputs, validatePassword } from "../../../services/utils";
import { useStyles } from './styles'
import Loader from "../../../components/loading";
import Alert from "../../../components/alert";
import { finance } from "../../../assets/images/img/index";
import { rockLogo } from '../../../assets/images/img/index';
import InputRow from "../../../components/inputComponents/inputRow";
import PrimaryButton from "../../../components/primaryButton/primaryButton";
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from "react-router-dom";
import { setUserDataInLocalStorage } from "../../../services/utils";
import './style.scss'
import { axios } from 'axios';
import HttpService from '../../../services/http.service';
import { mainImage } from '../../../assets/images/images';

const SignIn = (props) => {
  const classes = useStyles();
  const [state, setState] = useState({
    values: {
      email: "",
      // email: "",
      // email: "manager@manager.com",
      // email: "finance@finance.com",
      // email: "commercial@commercial.com",
      // email: "media@media.com",
      // password: "1234567",
      password: "",
      showPassword: false,
      rememberMe: false,
    },
    errors: {
      email: "",
      password: "",
    },
  });
  // useEffect(() => { 
  //   let  {email,password,rememberMe} = values;
  //   const info = {email,password,rememberMe}
  // localStorage.setItem("email",info.email)
  // },[]);

  useEffect(() => {
    if (localStorage.getItem("token") || localStorage.getItem("user")) {
      localStorage.clear();
      HttpService.removeUser();
    }
  }, []);
  useEffect(() => {
    const { user, error, success, loading } = props.authReducer;
    console.log("load", loading);
    if (props.authReducer.error) {
      setTimeout(() => {
        props.errorHandlerSuccess();
      }, 2000);
    } else {
      if (!loading && success && props.authReducer.user) {
        props.history.push("/dashboard");
        props.errorHandlerSuccess();
      }
    }
  }, [props.authReducer.error, props.authReducer.user]);


  // const  onChangeCheckbox = (event) => {
  //   setState({
  //     rememberMe: event.target.checked
  //   });
  // };

  const { values, errors } = state;

  const onLogin = () => {
    let { email, password, rememberMe } = values;
    const data = { email, password, rememberMe }
    let recordedErrors = validateInputs(data);
    if (Object.keys(recordedErrors).length > 0) {
      console.log(recordedErrors)
      setState((prevState) => ({
        ...prevState,
        errors: recordedErrors,
      }));
    } else {
      let recordedErrors = {}
      // if (!validateEmail(email)) {
      //   recordedErrors.email = 'Invalid Email';
      // }
      let passwordCheck = validatePassword(password);
      if (passwordCheck) {
        recordedErrors.password = passwordCheck;
      }
      if (Object.keys(recordedErrors).length > 0) {
        setState((prevState) => ({
          ...prevState,
          errors: recordedErrors,
        }));
      } else {
        const data = { email, password, rememberMe }
        props.onLoginHandler(data);
      }
    }
  };
  return (

    <Grid container component="main" className={classes.root} style={{height:'100vh'}}>
      <Grid container alignItems='center' xs={12} sm={12} md={12} lg={12} component={Paper} elevation={0} square className={'login-page-image-sec'}>
        <div className="loginPage" >
          <Grid container className='containerflix-column login-mg'>
            <div className='image-adjust'>
              <img src={rockLogo} />
            </div>
            <div className='paragraph-login'>
              <p>Log Into Your Account</p>
            </div>
          </Grid>
          <Grid container xs={12} direction={'column'} alignItems={"center"} justify={"center"}>
            {((props.authReducer?.success || props.authReducer?.error) && !props.authReducer?.loading) &&
              <Alert type={props.authReducer.error ? 'error' : 'success'}
                message={props.authReducer.error} />
            }
            <div className='makeStyles-formLogin-5'>
              <form className={classes.formLogin}>
                <InputRow
                  types={[
                    { type: 'text', value: values.email, error: errors.email, label: 'Email or Username', id: 'email' },
                    { type: 'password', value: values.password, error: errors.password, label: 'password', },
                    { type: 'checkbox', value: values.rememberMe, id: 'rememberMe', label: 'remember me', },
                  ]}
                  onChangeInput={(value, key) => onChangeInput(value, key, state, setState)}
                  numOfInputPerRow={1}
                />
                <Grid container className='MuiGrid-spacing-xs-3'>
                  <Grid item container xs={12} sm={12} md={12} lg={12} className='mt-20 align-self-start'>
                    <div className='text-icon' >
                      <RouterLink to={"/forgotPassword"} variant="body2" className='color-password-login'>
                        Forgot password?
                      </RouterLink>
                    </div>
                  </Grid>
                </Grid>

                <Grid container className='mb-20 align-self-start link-login'>
                  {props.authReducer.loading ?
                    <Loader /> :
                    <PrimaryButton className='round-btn-login' value={'Login'} onClick={onLogin} />
                  }
                </Grid>
              </form>
            </div>
          </Grid>
        </div>
        {/* <Grid item xs={12} sm={12} md={5}>
          <div className='img-rel'>
            <img src={mainImage} style={{height: '720px' }} />
            <h2>QR Code Scanning Portal</h2>
          </div>
        </Grid> */}
      </Grid>
      {/* <Link href='mailto:cpreports@rockvillegroup.com'>
        <h5 className='contact_us'>Contact Us
          <Tooltip title="cpreports@rockvillegroup.com" placement='top-start' arrow>
            <ContactSupportIcon fontSize='small' className='contact_icon' />
          </Tooltip>
        </h5>
      </Link> */}

    </Grid>
  );
}

const mapStateToProps = ({ authReducer }) => {
  return { authReducer };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onLoginHandler: (data) => dispatch(actions.loginStart(data)),
    errorHandlerSuccess: () => dispatch(actions.loginErrorHandlerSuccess()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
