import React, {useState} from 'react';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {TextField, withStyles} from "@material-ui/core";
import {styles} from "../inputStyle";
import {InputError} from "../../index";

function InputPassword(props) {

  const {
    item,
    onChange,
    classes
  } = props;

  const {
    id = 'password',
    type = 'password',
    label = 'Password',
    labelWidth = 70,
    value,
    error,
    required = true,
  } = item;

  const [showPassword, setShowPassword] = useState(false)
  return (
    <>
      <InputLabel className='mb-10' htmlFor={id || label} error={error}>{label}</InputLabel>
      <OutlinedInput
        id={id || label}
        type={showPassword ? 'text' : type}
        value={value}
        margin="normal"
        variant='outlined'
        required={required}
        fullWidth
        error={error}
        placeholder='********'
        onChange={(event) =>
          onChange(event.target.value, id)
        }
        // labelWidth={labelWidth}
        InputProps={{
          classes: {
            root: classes.cssOutlinedInput,
            focused: classes.cssFocused,
            notchedOutline: classes.notchedOutline,
          },
          inputMode: "numeric"
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
              edge="end"
            >
              {
              showPassword ? 
                // <Visibility/> 
                <div className='show-hide-pass'>
                  <span className='blue-span'>View</span>                  
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <g id="unlock 1">
                    <g id="Group">
                    <path id="Vector" fill-rule="evenodd" clip-rule="evenodd" d="M9.63038 2.88511C9.21863 2.78634 8.78738 2.78837 8.37668 2.89098L8.32545 2.90379C7.19597 3.18602 6.4066 4.10986 6.30632 5.17638L6.38016 5.16138C8.10765 4.81044 9.89235 4.81044 11.6198 5.16138L11.8269 5.20345C13.515 5.54638 14.8722 6.77982 15.3134 8.4087C15.7241 9.92498 15.625 11.5271 15.0311 12.9852L14.9615 13.1562C14.3242 14.7208 12.8799 15.8333 11.1758 16.0995L10.6142 16.1873C9.54503 16.3543 8.4549 16.3543 7.38581 16.1873L6.82427 16.0995C5.1201 15.8333 3.67579 14.7208 3.03854 13.1562L2.96888 12.9852C2.37502 11.5271 2.27596 9.92498 2.68664 8.4087C3.03629 7.11771 3.96137 6.07512 5.17051 5.5274V5.40704C5.17051 3.70037 6.36989 2.23284 8.05275 1.81235L8.10398 1.79954C8.69025 1.65305 9.3051 1.65017 9.8928 1.79114C10.5289 1.94373 11.1125 2.25992 11.58 2.7086L11.7716 2.89245C11.9958 3.10754 12.0032 3.46362 11.7881 3.68778C11.573 3.91194 11.2169 3.91929 10.9928 3.70421L10.8011 3.52036C10.4803 3.21251 10.076 2.99201 9.63038 2.88511ZM11.3959 6.26386C9.81623 5.94294 8.18378 5.94294 6.60413 6.26386L6.39705 6.30592C5.10623 6.56815 4.09721 7.5039 3.77251 8.70278C3.42646 9.98047 3.50957 11.3302 4.01078 12.5609L4.08044 12.7318C4.56253 13.9156 5.66686 14.78 6.99792 14.988L7.55948 15.0757C8.51348 15.2248 9.48645 15.2248 10.4405 15.0757L11.0021 14.988C12.3332 14.78 13.4375 13.9156 13.9196 12.7318L13.9892 12.5609C14.4905 11.3302 14.5736 9.98047 14.2275 8.70278C13.9028 7.5039 12.8938 6.56815 11.603 6.30592L11.3959 6.26386Z" fill="#92929D"/>
                    <path id="Vector_2" fill-rule="evenodd" clip-rule="evenodd" d="M9 9.5625C9.31065 9.5625 9.5625 9.81435 9.5625 10.125V11.625C9.5625 11.9357 9.31065 12.1875 9 12.1875C8.68935 12.1875 8.4375 11.9357 8.4375 11.625V10.125C8.4375 9.81435 8.68935 9.5625 9 9.5625Z" fill="#92929D"/>
                    </g>
                    </g>
                  </svg>
                </div>
                  : 
                // <VisibilityOff/>
                <div className='show-hide-pass'>
                  <span><s>View</s></span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <g id="unlock 1">
                    <g id="Group">
                    <path id="Vector" fill-rule="evenodd" clip-rule="evenodd" d="M9.63038 2.88511C9.21863 2.78634 8.78738 2.78837 8.37668 2.89098L8.32545 2.90379C7.19597 3.18602 6.4066 4.10986 6.30632 5.17638L6.38016 5.16138C8.10765 4.81044 9.89235 4.81044 11.6198 5.16138L11.8269 5.20345C13.515 5.54638 14.8722 6.77982 15.3134 8.4087C15.7241 9.92498 15.625 11.5271 15.0311 12.9852L14.9615 13.1562C14.3242 14.7208 12.8799 15.8333 11.1758 16.0995L10.6142 16.1873C9.54503 16.3543 8.4549 16.3543 7.38581 16.1873L6.82427 16.0995C5.1201 15.8333 3.67579 14.7208 3.03854 13.1562L2.96888 12.9852C2.37502 11.5271 2.27596 9.92498 2.68664 8.4087C3.03629 7.11771 3.96137 6.07512 5.17051 5.5274V5.40704C5.17051 3.70037 6.36989 2.23284 8.05275 1.81235L8.10398 1.79954C8.69025 1.65305 9.3051 1.65017 9.8928 1.79114C10.5289 1.94373 11.1125 2.25992 11.58 2.7086L11.7716 2.89245C11.9958 3.10754 12.0032 3.46362 11.7881 3.68778C11.573 3.91194 11.2169 3.91929 10.9928 3.70421L10.8011 3.52036C10.4803 3.21251 10.076 2.99201 9.63038 2.88511ZM11.3959 6.26386C9.81623 5.94294 8.18378 5.94294 6.60413 6.26386L6.39705 6.30592C5.10623 6.56815 4.09721 7.5039 3.77251 8.70278C3.42646 9.98047 3.50957 11.3302 4.01078 12.5609L4.08044 12.7318C4.56253 13.9156 5.66686 14.78 6.99792 14.988L7.55948 15.0757C8.51348 15.2248 9.48645 15.2248 10.4405 15.0757L11.0021 14.988C12.3332 14.78 13.4375 13.9156 13.9196 12.7318L13.9892 12.5609C14.4905 11.3302 14.5736 9.98047 14.2275 8.70278C13.9028 7.5039 12.8938 6.56815 11.603 6.30592L11.3959 6.26386Z" fill="#92929D"/>
                    <path id="Vector_2" fill-rule="evenodd" clip-rule="evenodd" d="M9 9.5625C9.31065 9.5625 9.5625 9.81435 9.5625 10.125V11.625C9.5625 11.9357 9.31065 12.1875 9 12.1875C8.68935 12.1875 8.4375 11.9357 8.4375 11.625V10.125C8.4375 9.81435 8.68935 9.5625 9 9.5625Z" fill="#92929D"/>
                    </g>
                    </g>
                  </svg>
                </div>
              }
            </IconButton>
          </InputAdornment>}
      />
      {/*error message*/}
      {error && <InputError message={error}/>}
      {/*<p className='error-class'>{error}</p>*/}
    </>
  );
}

export default withStyles(styles)(InputPassword);
