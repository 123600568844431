import React, { useState, useEffect } from 'react';

import { CSVLink } from "react-csv";
import Loader from '../loading'

function NewDownloadAddButton(props) {
  const { openModal, downloadHandler, loading, fileName } = props;
  const [userData, setUserData] = useState(null);
  const isAdmin = userData && userData.role.type === 1;

  useEffect(() => {
    const storedUserData = JSON.parse(localStorage.getItem('user'));
    setUserData(storedUserData);
  }, []); 

  return isAdmin ? (
    <div>
      {/* /////add-icon/// */}
      <span className={downloadHandler ? 'new-add-icon two-iconst' : 'new-add-icon'}>
        <div className='display-flex align-items-center'>
          {/* <a href='https://atlasguard.s3.us-east-1.amazonaws.com/Manifest/assets/albania-sample.XLSX'>
            <div className='file-download-wrapper'>
              <div className='file-download-circle'>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <g id="file-download 1">
                    <g id="Group">
                      <path id="Vector" d="M7.5 14.792C7.41667 14.792 7.34167 14.7753 7.25833 14.742C7.025 14.6503 6.875 14.417 6.875 14.167V9.16699C6.875 8.82533 7.15833 8.54199 7.5 8.54199C7.84167 8.54199 8.125 8.82533 8.125 9.16699V12.6587L8.725 12.0587C8.96667 11.817 9.36667 11.817 9.60833 12.0587C9.85 12.3003 9.85 12.7003 9.60833 12.942L7.94167 14.6087C7.825 14.7253 7.65833 14.792 7.5 14.792Z" fill="#242B42" />
                      <path id="Vector_2" d="M7.50028 14.7915C7.34194 14.7915 7.18361 14.7332 7.05861 14.6082L5.39194 12.9415C5.15028 12.6999 5.15028 12.2999 5.39194 12.0582C5.63361 11.8165 6.03361 11.8165 6.27528 12.0582L7.94194 13.7249C8.18361 13.9665 8.18361 14.3665 7.94194 14.6082C7.81694 14.7332 7.65861 14.7915 7.50028 14.7915Z" fill="#242B42" />
                      <path id="Vector_3" d="M12.5 18.9587H7.49996C2.97496 18.9587 1.04163 17.0253 1.04163 12.5003V7.50033C1.04163 2.97533 2.97496 1.04199 7.49996 1.04199H11.6666C12.0083 1.04199 12.2916 1.32533 12.2916 1.66699C12.2916 2.00866 12.0083 2.29199 11.6666 2.29199H7.49996C3.65829 2.29199 2.29163 3.65866 2.29163 7.50033V12.5003C2.29163 16.342 3.65829 17.7087 7.49996 17.7087H12.5C16.3416 17.7087 17.7083 16.342 17.7083 12.5003V8.33366C17.7083 7.99199 17.9916 7.70866 18.3333 7.70866C18.675 7.70866 18.9583 7.99199 18.9583 8.33366V12.5003C18.9583 17.0253 17.025 18.9587 12.5 18.9587Z" fill="#242B42" />
                      <path id="Vector_4" d="M18.3333 8.95841H15C12.15 8.95841 11.0416 7.85007 11.0416 5.00007V1.66674C11.0416 1.41674 11.1916 1.18341 11.425 1.09174C11.6583 0.991739 11.925 1.05007 12.1083 1.22507L18.775 7.89174C18.95 8.06674 19.0083 8.34175 18.9083 8.57508C18.8083 8.80841 18.5833 8.95841 18.3333 8.95841ZM12.2916 3.17507V5.00007C12.2916 7.15007 12.85 7.70841 15 7.70841H16.825L12.2916 3.17507Z" fill="#242B42" />
                    </g>
                  </g>
                </svg>
              </div>
              <span className='file-download-span'>Albania Sample</span>
            </div>
          </a> */}
          {/* <a href='https://atlasguard.s3.us-east-1.amazonaws.com/Manifest/assets/germany-sample.XLSX'>
            <div className='file-download-wrapper'>
              <div className='file-download-circle'>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <g id="file-download 1">
                    <g id="Group">
                      <path id="Vector" d="M7.5 14.792C7.41667 14.792 7.34167 14.7753 7.25833 14.742C7.025 14.6503 6.875 14.417 6.875 14.167V9.16699C6.875 8.82533 7.15833 8.54199 7.5 8.54199C7.84167 8.54199 8.125 8.82533 8.125 9.16699V12.6587L8.725 12.0587C8.96667 11.817 9.36667 11.817 9.60833 12.0587C9.85 12.3003 9.85 12.7003 9.60833 12.942L7.94167 14.6087C7.825 14.7253 7.65833 14.792 7.5 14.792Z" fill="#242B42" />
                      <path id="Vector_2" d="M7.50028 14.7915C7.34194 14.7915 7.18361 14.7332 7.05861 14.6082L5.39194 12.9415C5.15028 12.6999 5.15028 12.2999 5.39194 12.0582C5.63361 11.8165 6.03361 11.8165 6.27528 12.0582L7.94194 13.7249C8.18361 13.9665 8.18361 14.3665 7.94194 14.6082C7.81694 14.7332 7.65861 14.7915 7.50028 14.7915Z" fill="#242B42" />
                      <path id="Vector_3" d="M12.5 18.9587H7.49996C2.97496 18.9587 1.04163 17.0253 1.04163 12.5003V7.50033C1.04163 2.97533 2.97496 1.04199 7.49996 1.04199H11.6666C12.0083 1.04199 12.2916 1.32533 12.2916 1.66699C12.2916 2.00866 12.0083 2.29199 11.6666 2.29199H7.49996C3.65829 2.29199 2.29163 3.65866 2.29163 7.50033V12.5003C2.29163 16.342 3.65829 17.7087 7.49996 17.7087H12.5C16.3416 17.7087 17.7083 16.342 17.7083 12.5003V8.33366C17.7083 7.99199 17.9916 7.70866 18.3333 7.70866C18.675 7.70866 18.9583 7.99199 18.9583 8.33366V12.5003C18.9583 17.0253 17.025 18.9587 12.5 18.9587Z" fill="#242B42" />
                      <path id="Vector_4" d="M18.3333 8.95841H15C12.15 8.95841 11.0416 7.85007 11.0416 5.00007V1.66674C11.0416 1.41674 11.1916 1.18341 11.425 1.09174C11.6583 0.991739 11.925 1.05007 12.1083 1.22507L18.775 7.89174C18.95 8.06674 19.0083 8.34175 18.9083 8.57508C18.8083 8.80841 18.5833 8.95841 18.3333 8.95841ZM12.2916 3.17507V5.00007C12.2916 7.15007 12.85 7.70841 15 7.70841H16.825L12.2916 3.17507Z" fill="#242B42" />
                    </g>
                  </g>
                </svg>
              </div>
              <span className='file-download-span'>Germany Sample</span>
            </div>
          </a> */}
          <span className="cn_item second-ic cursor-pointer" onClick={() => openModal()} title='Add New'>
            Create Manifest
          </span>
        </div>

      </span>
    </div>
  ) : null;
}

export default NewDownloadAddButton;
