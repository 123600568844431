import * as actionTypes from "../actionTypes";

const initialState = {
  loading: false,
  user: null,
  error: null,
  success: null,
  resetPasswordSuccess: null
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_START:
      return { ...state, loading: true, error: null, success: null, user: null };
    case actionTypes.LOGIN_SUCCESS:
      return { ...state, loading: false, error: null, success: action.payload.message, user: action.payload.user };
    case actionTypes.LOGIN_FAILURE:
      return { ...state, loading: false, error: action.payload, success: null, user: null };

    case actionTypes.SIGNUP_START:
      return { ...state, loading: true, error: null, success: null, user: null };
    case actionTypes.SIGNUP_SUCCESS:
      return { ...state, loading: false, error: null, success: action.payload.success, user: action.payload.user };
    case actionTypes.SIGNUP_FAILURE:
      return { ...state, loading: false, error: action.payload, message: null, user: null };

    case actionTypes.CHANGE_PASSWORD_START:
      return { ...state, loading: true, error: null, success: null, user: null };
    case actionTypes.CHANGE_PASSWORD_SUCCESS:
      return { ...state, loading: false, error: null, success: action.payload };
    case actionTypes.CHANGE_PASSWORD_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case actionTypes.FORGOT_PASSWORD_START:
      return { ...state, loading: true, error: null, success: null, user: null };
    case actionTypes.FORGOT_PASSWORD_SUCCESS:
      return { ...state, loading: false, error: null, success: action.payload };
    case actionTypes.FORGOT_PASSWORD_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case actionTypes.VERIFY_PASSWORD_START:
      return { ...state, loading: true, error: null, success: null, user: null };
    case actionTypes.VERIFY_PASSWORD_SUCCESS:
      return { ...state, loading: false, error: null, success: action.payload, resetPasswordSuccess: action.payload };
    case actionTypes.VERIFY_PASSWORD_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case actionTypes.LOGOUT:
      return { ...state, loading: true, error: null, success: null, user: {} };
    case actionTypes.LOGOUT_SUCCESS:
      return { ...state, loading: false, error: null, success: null, user: null };

    case actionTypes.LOGIN_ERROR_HANDLER_SUCCESS:
      return { ...state, loading: false, error: action.payload, success: action.payload, resetPasswordSuccess: action.payload };
    default:
      return { ...state };
  }
};
